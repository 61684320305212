import React from 'react'

import Carousel from '@components/Carousel'
import SleepcheckContext from '@context/SleepCheckContext'
import { IPersonalizedSleepTipList } from '@modules/contentSections/types'
import TopListItem from './TopListItem'

const PersonalizedSleepTipList: React.FC<IPersonalizedSleepTipList> = (props) => {
  const { items, headline, id } = props
  const { isLoading } = React.useContext(SleepcheckContext)
  const filteredCarouselIconItems = items.filter((item) => !item.personalized)
  const carouselIconItems = filteredCarouselIconItems.map((listItem, index) => (
    <TopListItem
      {...listItem}
      isLoading={isLoading}
      key={listItem.id}
      positionIndicator={`${index + 1}/${filteredCarouselIconItems.length}`}
    />
  ))

  return (
    <section id={id} className="bg-white section-content-width section-spacing">
      <div className="-mb-4">
        <h2 className="headline-section mb-6 md:ml-1">{headline}</h2>

        <Carousel
          id={id}
          carouselItems={carouselIconItems}
          slidesToShowMobile={1}
          slidesToShowDesktop={1}
          width="fullOnDesktop"
        />
      </div>
    </section>
  )
}
export default PersonalizedSleepTipList
