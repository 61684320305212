import { ICategory } from '@modules/category/types'
import { getTargetForCta } from '@modules/common/utils'
import { IDatoCMSCategoryPage } from '../types/category'
import { mapCallToAction, mapImage } from './misc'

export const mapCategoryPage = (data: IDatoCMSCategoryPage): ICategory => {
  if (!data) {
    return undefined
  }
  const stageSection = data.stageSection[0]
  const hasCallToAction = stageSection.callToAction.length > 0
  const targetId = getTargetForCta(stageSection.callToAction[0], data.contentSections)
  return {
    ...data,
    stageSection: {
      ...stageSection,
      image: mapImage(stageSection.image),
      callToAction: hasCallToAction ? mapCallToAction(stageSection.callToAction[0], targetId) : null
    }
  }
}
