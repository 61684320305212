import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { IProductSnippetsBySlug } from '@modules/products/types'
import Carousel from '@components/Carousel'
import { IDatoCMSBundleTeaserRecord } from '@lib/datocms/types'
import { getDatoIndexKey } from '@lib/productService/utils'
import { Price } from '@components/Price'
import ArrowToRightIcon from '@components/icons/ArrowToRightIcon'
import Product from './Products/Product'

type BundleProps = {
  bundleTeaserRecord: IDatoCMSBundleTeaserRecord
  productSnippetsBySlug: IProductSnippetsBySlug
}

const BundleTeaser = ({ bundleTeaserRecord, productSnippetsBySlug }: BundleProps) => {
  const bundleAsProduct = productSnippetsBySlug[getDatoIndexKey(bundleTeaserRecord.bundleAsProduct)]

  const showBundleBatch = bundleAsProduct.isBundle

  const mainImage = bundleAsProduct.firstImage
  const carouselItems = bundleTeaserRecord.bundleAsProduct.bundleComponents.map(
    (datoCMSProduct) => {
      const key = datoCMSProduct.id
      const singleProduct = productSnippetsBySlug[getDatoIndexKey(datoCMSProduct)]
      return (
        <Product key={key} product={singleProduct} imageSizes="(min-width: 767px) 25vw, 100vw" />
      )
    }
  )

  return (
    <section
      id={bundleTeaserRecord.id}
      className="bg-white section-spacing section-content-width cursor-pointer">
      <Link legacyBehavior href={`/products/${bundleAsProduct.shopifyProductHandle}`}>
        <div className="w-full flex flex-col justify-start p-4 lg:p-0 bg-gray-200 lg:bg-transparent">
          <h4 className="hidden lg:block overline-section mb-4 md:ml-1">
            {bundleTeaserRecord.headline}
          </h4>
          {/* mobile headline 👇🏼 */}
          <div className="flex flex-col lg:flex-row ">
            <div className="order-1 lg:hidden">
              <h2 className="uppercase text-xs md:text-sm tracking-wide">
                {bundleTeaserRecord.overline}
              </h2>
              <h4 className="overline-section mt-1 lg:mt-2 mb-2">{bundleTeaserRecord.headline}</h4>
            </div>
            <div className="order-2 flex flex-col justify-center lg:w-[70%] lg:-ml-20 z-1">
              <div className="bg-gray-200 justify-center self-start pl-1 lg:p-16">
                <h4 className="font-bold text-base w-full break-words mt-5 lg:mt-0">
                  {bundleAsProduct.name}
                </h4>
                <div className="mt-2 lg:mt-5">
                  <div
                    className="mt-1 text-base text-content-section small-box-list"
                    dangerouslySetInnerHTML={{ __html: bundleTeaserRecord.description }}
                  />
                  <div className="mt-4 lg:mt-6">
                    <Price product={bundleAsProduct} showBundleText />
                  </div>
                  <button
                    type="button"
                    className="btn btn--black flex flex-grow justify-center mt-4 lg:mt-6 z-10 w-2/3">
                    <ArrowToRightIcon />
                    <span>Zum Bundle</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="w-full order-1 relative">
              <div className="w-full aspect-w-4 aspect-h-3">
                <Image
                  src={bundleTeaserRecord.image.responsiveImage.src}
                  alt={mainImage.alt}
                  fill
                  className="object-cover"
                  style={{
                    objectPosition: `${mainImage.focalPoint.x * 100}% ${
                      mainImage.focalPoint.y * 100
                    }% `
                  }}
                  sizes="(min-width: 1020px) 60vw, 100vw"
                />
              </div>
              {showBundleBatch && (
                <div className="absolute top-0 left-0 m-4 md:m-6 py-1 px-2 uppercase tracking-wider bg-black text-white text-xs font-bold z-10">
                  Bundle
                </div>
              )}
            </div>
          </div>
          <h2 className="overline-section my-6 lg:mt-6 ">IM BUNDLE ENTHALTEN</h2>
          <div>
            <Carousel
              id={bundleTeaserRecord.id}
              carouselItems={carouselItems}
              slidesToShowMobile={1}
              slidesToShowDesktop={4}
              width="trimmed"
              showAllOnDesktop
            />
          </div>
        </div>
      </Link>
    </section>
  )
}

export default BundleTeaser
