import React from 'react'
import { PlusIcon } from '@heroicons/react/24/outline'
import { Disclosure } from '@headlessui/react'

import { IDatoCMSFaqList } from '@lib/datocms/types/contentSections'

interface IFaqList {
  faqList: IDatoCMSFaqList
  id: string
}

const FaqList: React.FC<IFaqList> = ({ faqList, id }) => (
  <section id={id} className="bg-white section-content-width section-spacing">
    <div className="flex flex-col w-full">
      <h2 className="overline-section mb-2 md:mb-4">{faqList.headline}</h2>
      <div className="w-full">
        {faqList.questionList.map((listItem) => (
          <Disclosure as="div" className="border-b border-gray-700" key={listItem.id}>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex items-center text-left justify-between text-base md:text-lg focus:outline-none font-bold hover:text-gray-600 hover:underline text-black mt-1 md:mt-1 py-2 md:py-6 w-full">
                  <span>{listItem.question}</span>

                  <div className="flex ml-4">
                    {/* eslint-disable-next-line */}
                    <PlusIcon
                      className={`${
                        open ? 'transform rotate-45' : ''
                      } w-7 h-7 ease-in-out transition`}
                    />
                  </div>
                </Disclosure.Button>

                <Disclosure.Panel className="text-left mb-16 max-w-5xl py-2 lg:pr-16">
                  <div
                    className="pb-4 text-base"
                    dangerouslySetInnerHTML={{ __html: listItem.answer }}
                  />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </div>
    </div>
  </section>
)

export default FaqList
