import React from 'react'
import Image from 'next/image'
import { YoImageOrVideo } from '@modules/common/types'

type ExpertSingleProps = {
  id: string
  name: string
  categories: {
    name: string
  }[]
  shortDescription: string
  image: YoImageOrVideo
}
const ExpertSingle = ({ id, image, categories, name, shortDescription }: ExpertSingleProps) => {
  const categoriesExpertHeadline = `${categories
    .map((authorCategory) => authorCategory.name)
    .join(', ')} Experte`

  return (
    <section
      id={id}
      className="flex flex-col max-w-content-sm mx-4 my-16 p-4 bg-gray-200 lg:flex-row lg:items-center lg:mx-auto lg:rounded-br-lg lg:rounded-tl-lg lg:p-12">
      <div className="w-3/5 aspect-square relative self-center -mt-10 mb-6 lg:w-1/2 lg:my-0 flex-shrink-0">
        <Image
          alt={image.alt}
          src={image.responsiveImage}
          fill
          style={{ objectPosition: image.focalPoint }}
          className="rounded-full object-cover"
          sizes="50vw"
        />
      </div>
      <div className="flex flex-col lg:px-12 lg:w-6/12 flex-shrink-0">
        <h3 className="text-sm font-bold uppercase">{categoriesExpertHeadline}</h3>
        <p className="text-2xl font-bold mt-1 mb-3 lg:pb-0">{name}</p>
        <div>
          <div dangerouslySetInnerHTML={{ __html: shortDescription }} className="text-base" />
          {/* <Link legacyBehavior href={`/experts/${author.slug}`}>Mehr</Link> */}
        </div>
      </div>
    </section>
  )
}

export default ExpertSingle
