import { getTargetForCta } from '@modules/common/utils'
import { IHomepage } from '@modules/homepage/types'
import { IDatoCMSHomepage } from '@lib/datocms/types'
import { mapCallToAction, mapImage, mapSeoTags } from './misc'

export const mapHomepage = (data: IDatoCMSHomepage): IHomepage => {
  const stageSection = data.stageSection[0]
  const hasCallToAction = stageSection.callToAction.length > 0
  const targetId = getTargetForCta(stageSection.callToAction[0], data.contentSections)
  const stageImages = stageSection.images.map((image) => mapImage(image))

  return {
    ...data,
    stageSection: {
      ...stageSection,
      images: stageImages,
      callToAction: hasCallToAction ? mapCallToAction(stageSection.callToAction[0], targetId) : null
    },
    seoTags: mapSeoTags(data.seoTags)
  }
}
