import React from 'react'

import Carousel from '@components/Carousel'
import { ExpertsProps } from '@modules/contentSections/types'
import ExpertSlide from './Experts/ExpertSlide'
import ExpertSingle from './Experts/ExpertSingle'

const Experts = ({ id, headline, experts }: ExpertsProps) => {
  const carouselItems: JSX.Element[] = experts.map((expert) => (
    <ExpertSlide {...expert} key={expert.name} />
  ))

  return (
    <section id={id}>
      {experts.length !== 1 && (
        <section className="bg-white section-content-width section-spacing">
          <h2 className="overline-section mb-8 md:mb-10">{headline}</h2>
          <Carousel
            id={id}
            carouselItems={carouselItems}
            slidesToShowDesktop={3}
            slidesToShowMobile={1}
            width="trimmed"
            gap={4}
          />
        </section>
      )}
      {experts.length === 1 && (
        <ExpertSingle
          id={experts[0].slug}
          categories={experts[0].categories}
          image={experts[0].image}
          name={experts[0].name}
          shortDescription={experts[0].text}
        />
      )}
    </section>
  )
}

export default Experts
