import React from 'react'
import dynamic from 'next/dynamic'
import Categories from '@components/content/contentSection/Categories'
import Editorial from '@components/content/contentSection/Editorial'
import Experts from '@components/content/contentSection/Experts'
import StandardCoachingPricing from '@components/content/subscriptions/StandardCoachingPricing'
import PricingComparisonTable from '@components/content/subscriptions/PricingComparisonTable'
import MoodImage from '@components/content/contentSection/MoodImage'
import ThematicRecommendation from '@components/content/contentSection/ThematicRecommendation'
import {
  IDatoCMSBenefitList,
  IDatoCMSBrandIntro,
  IDatoCMSCallToActionSection,
  IDatoCMSCategoryPostsTeaser,
  IDatoCMSCommunityStoryOverview,
  IDatoCMSDivider,
  IDatoCMSFaqList,
  IDatoCMSFeaturedIn,
  IDatoCMSFounderStory,
  IDatoCMSIconsWithText,
  IDatoCMSImageGallery,
  IDatoCMSMasterclassesCategoryTeaser,
  IDatoCMSMasterclassesTeaser,
  IDatoCMSPodcastTeaser,
  IDatoCMSPostsTeaser,
  IDatoCMSSleepScore,
  IDatoCMSSocialMedia,
  IDatoCMSTestimonialOverview,
  IDatoCMSTextContentSection,
  IDatoCMSTopicIntro,
  IDatoCMSTopicOverview,
  IDatoCMSTopXList,
  IDatoCMSCategoriesTeaserRecord,
  IDatoCMSExpertsTeaserRecord,
  IDatoCMSMoodImageRecord,
  IDatoCMSNewsLetterSignUp,
  IDatoCMSProductListRecord,
  IDatoCMSOneImage,
  IDatoCMSBrandsTeaserRecord,
  IDatoCMSPricingTable
} from '@lib/datocms/types/contentSections'
import {
  IDatoCMSBundleTeaserRecord,
  IDatoCMSThematicRecommendationRecord
} from '@lib/datocms/types/product'
import Products from '@components/content/contentSection/Products'
import ExpertRecommendation from '@components/content/category/ContentSections/ExpertRecommendation'
import { IDatoCMSExpertRecommendationRecord } from '@modules/category/types'
import BrandIntro from '@components/content/contentSection/BrandIntro'
import BenefitList from '@components/content/contentSection/BenefitList'
import TopicOverview from '@components/content/contentSection/TopicOverview'
import FounderStory from '@components/content/contentSection/FounderStory'
import FaqList from '@components/content/contentSection/FaqList'
import TestimonialOverview from '@components/content/contentSection/TestimonialOverview'
import SocialMedia from '@components/content/contentSection/SocialMedia'
import DividerWithIcon from '@components/content/contentSection/DividerWithIcon'
import { IDatoCMSContentSection, IStep, ModelApiKey } from '@lib/datocms/types/modelApiKeysEnum'
import { IDatoCMSHeroProduct, IProductSnippetsBySlug } from '@modules/products/types'
import NewsletterSignUp from '@components/content/homepage/NewsletterSignUp'
import TopicIntro from '@components/content/topic/TopicIntro'
import TextContentSection from '@components/content/contentSection/TextContentSection'
import PostsTeaser from '@components/content/contentSection/PostsTeaser'
import PodcastTeaser from '@components/content/contentSection/PodcastTeaser'
import IconsWithText from '@components/content/contentSection/IconsWithText'
import CategoryPostsTeaser from '@components/content/contentSection/CategoryPostsTeaser'
import MasterclassesTeaser from '@components/content/contentSection/MasterclassesTeaser'
import MasterclassesCategoryTeaser from '@components/content/contentSection/MasterclassesCategoryTeaser'
import FeaturedIn from '@components/content/contentSection/FeaturedIn'
import TopXList from '@components/content/contentSection/TopXList'
import OneImage from '@components/content/contentSection/OneImage'
import ImageGallery from '@components/content/contentSection/ImageGallery'
import HeroProduct from '@components/content/contentSection/HeroProduct'
import CallToActionSection from '@components/content/contentSection/CallToActionSection'
import BundleTeaser from '@components/content/contentSection/BundleTeaser'
import CommunityStoryOverview from '@components/content/contentSection/CommunityStoriesWithVideo'
import StepDivider from '@components/content/contentSection/StepDivider'

import {
  mapPersonalizedHeroProduct,
  mapPersonalizedSleepTipList,
  mapPersonalizedPostsTeaser,
  mapSleepScoreSection,
  mapPersonalizedTextProductAndPost,
  mapPersonalizedHeroProductList
} from '@lib/datocms/mapper'
import InstagramStoriesGallery from '@components/content/contentSection/InstagramStoriesGallery'
import PersonalizedSleepTipList from '@components/content/contentSection/PersonalizedSleepTipList'
import SleepCheckContext from '@context/SleepCheckContext'
import {
  IDatoCMSPersonalizableTextProductAndPost,
  IDatoCMSPersonalizedPostsTeaser,
  IDatoCMSPersonalizedSleepTipList
} from '@lib/datocms/types/personalizedContent'
import {
  mapBenefitListSection,
  mapBrandIntro,
  mapBrandsTeaserSection,
  mapCallToActionSection,
  mapCategoriesTeaserSection,
  mapExpertRecommendation,
  mapExpertsTeaser,
  mapImageGallerySection,
  mapMoodImage,
  mapOneImageBlock,
  mapTextContentSection,
  mapThematicRecommendation,
  mapTopXList
} from '@lib/datocms/mapper/contentSections'
import { IDatoCMSEditorialRecord } from '@lib/datocms/types'
import PersonalizedTextWithProductListOrPostList from '@components/content/contentSection/PersonalizedTextWithProductListOrPostList'
import BrandsTeaser from '@components/content/contentSection/BrandsTeaser'
import PersonalizedHeroProductList from '@components/content/contentSection/PersonalizedHeroProductList'
import { IDatoCMSPersonalizableHeroProductList } from '@lib/datocms/types/personalizedContent/heroProductList'
import Journey from '@components/content/contentSection/Journey'
import { IDatoCMSJourney } from '@lib/datocms/types/journey'
import { mapJourney } from '@lib/datocms/mapper/journey'
import ComparisonTable from '@components/content/contentSection/ComparisonTable'
import GptAssessment from '@components/content/contentSection/GptAssessment'

const DynamicSleepScore = dynamic(() => import('@components/SleepScore'), {
  loading: () => <p>Loading...</p>
})

type ContentSectionListProps = {
  contentSections: IDatoCMSContentSection[]
  productSnippetsBySlug: IProductSnippetsBySlug
  steps?: IStep[]
}

const ContentSectionList = ({
  contentSections,
  productSnippetsBySlug,
  steps
}: ContentSectionListProps) => {
  const { result: sleepCheckResults } = React.useContext(SleepCheckContext)
  let stepDividerIndex = 0

  return (
    <>
      {contentSections.map((contentSection) => {
        const modelApiKey = contentSection._modelApiKey

        switch (modelApiKey) {
          case ModelApiKey.BENEFIT_LIST:
            const benefitListData = mapBenefitListSection(
              contentSection as IDatoCMSBenefitList,
              contentSections
            )
            return <BenefitList {...benefitListData} key={benefitListData.id} />

          case ModelApiKey.BRAND_INTRO:
            const brandIntroData = mapBrandIntro(
              contentSection as IDatoCMSBrandIntro,
              contentSections
            )
            return <BrandIntro {...brandIntroData} key={brandIntroData.id} />

          case ModelApiKey.BRANDS_TEASER:
            const brandTeaserSection = mapBrandsTeaserSection(
              contentSection as IDatoCMSBrandsTeaserRecord
            )
            return <BrandsTeaser {...brandTeaserSection} key={contentSection.id} />

          case ModelApiKey.CATEGORY_TEASER:
            const categoriesTeaserSection = mapCategoriesTeaserSection(
              contentSection as IDatoCMSCategoriesTeaserRecord
            )
            return <Categories {...categoriesTeaserSection} key={contentSection.id} />

          case ModelApiKey.EDITORIAL:
            return (
              <Editorial
                editorialRecord={contentSection as IDatoCMSEditorialRecord}
                key={contentSection.id}
                id={contentSection.id}
              />
            )

          case ModelApiKey.EXPERT_TEASER:
            const expertTeaser = mapExpertsTeaser(contentSection as IDatoCMSExpertsTeaserRecord)
            return <Experts {...expertTeaser} key={contentSection.id} />

          case ModelApiKey.EXPERT_RECOMMENDATION:
            const expertRecommendation = mapExpertRecommendation(
              contentSection as IDatoCMSExpertRecommendationRecord
            )
            return (
              <ExpertRecommendation
                expertRecommendationData={expertRecommendation}
                productSnippetsBySlug={productSnippetsBySlug}
                key={contentSection.id}
              />
            )

          case ModelApiKey.MOOD_IMAGE:
            const moodImageSection = mapMoodImage(contentSection as IDatoCMSMoodImageRecord)
            return <MoodImage {...moodImageSection} key={contentSection.id} />

          case ModelApiKey.THEMATIC_RECOMMENDATION:
            const thematicRecommendation = mapThematicRecommendation(
              contentSection as IDatoCMSThematicRecommendationRecord
            )
            return (
              <ThematicRecommendation
                {...thematicRecommendation}
                productSnippetsBySlug={productSnippetsBySlug}
                key={contentSection.id}
              />
            )

          case ModelApiKey.PRODUCT_LIST:
            return (
              <Products
                productListRecord={contentSection as IDatoCMSProductListRecord}
                productSnippetsBySlug={productSnippetsBySlug}
                key={contentSection.id}
                id={contentSection.id}
              />
            )

          case ModelApiKey.TOPIC_OVERVIEW:
            return (
              <TopicOverview
                topicOverview={contentSection as IDatoCMSTopicOverview}
                key={contentSection.id}
                id={contentSection.id}
              />
            )
          case ModelApiKey.FOUNDER_STORY:
            return (
              <FounderStory
                founderStory={contentSection as IDatoCMSFounderStory}
                key={contentSection.id}
                id={contentSection.id}
              />
            )
          case ModelApiKey.FAQ_LIST:
            return (
              <FaqList
                faqList={contentSection as IDatoCMSFaqList}
                key={contentSection.id}
                id={contentSection.id}
              />
            )
          case ModelApiKey.TESTIMONIAL_OVERVIEW:
            return (
              <TestimonialOverview
                testimonialOverview={contentSection as IDatoCMSTestimonialOverview}
                key={contentSection.id}
                id={contentSection.id}
              />
            )
          case ModelApiKey.SOCIAL_MEDIA_BLOCK:
            return (
              <SocialMedia
                socialMedia={contentSection as IDatoCMSSocialMedia}
                key={contentSection.id}
                id={contentSection.id}
              />
            )
          case ModelApiKey.DIVIDER:
            return (
              <DividerWithIcon {...(contentSection as IDatoCMSDivider)} key={contentSection.id} />
            )

          case ModelApiKey.NEWSLETTER_TEASER:
            return (
              <NewsletterSignUp
                {...(contentSection as IDatoCMSNewsLetterSignUp)}
                key={contentSection.id}
                id={contentSection.id}
              />
            )
          case ModelApiKey.TOPIC_INTRO:
            return (
              <TopicIntro
                topicIntro={contentSection as IDatoCMSTopicIntro}
                key={contentSection.id}
                id={contentSection.id}
              />
            )
          case ModelApiKey.FEATURED_IN:
            return (
              <FeaturedIn
                {...(contentSection as IDatoCMSFeaturedIn)}
                key={contentSection.id}
                id={contentSection.id}
              />
            )
          case ModelApiKey.COMMUNITY_STORY_OVERVIEW:
            return (
              <CommunityStoryOverview
                communityStoryOverview={contentSection as IDatoCMSCommunityStoryOverview}
                key={contentSection.id}
                id={contentSection.id}
              />
            )

          case ModelApiKey.TEXT_CONTENT_SECTION:
            const textContentSection = mapTextContentSection(
              contentSection as IDatoCMSTextContentSection
            )
            return (
              <TextContentSection
                {...textContentSection}
                key={contentSection.id}
                id={contentSection.id}
              />
            )
          case ModelApiKey.POSTS_TEASER:
            return (
              <PostsTeaser {...(contentSection as IDatoCMSPostsTeaser)} key={contentSection.id} />
            )

          case ModelApiKey.CATEGORY_POSTS_TEASER:
            return (
              <CategoryPostsTeaser
                categoryPostsTeaser={contentSection as IDatoCMSCategoryPostsTeaser}
                key={contentSection.id}
                id={contentSection.id}
              />
            )

          case ModelApiKey.ICONS_WITH_TEXT:
            return (
              <IconsWithText
                iconsWithText={contentSection as IDatoCMSIconsWithText}
                key={contentSection.id}
                id={contentSection.id}
              />
            )
          case ModelApiKey.PODCAST_TEASER:
            return (
              <PodcastTeaser
                podcastTeaser={contentSection as IDatoCMSPodcastTeaser}
                key={contentSection.id}
                id={contentSection.id}
              />
            )
          case ModelApiKey.MASTERCLASSES_TEASER:
            return (
              <MasterclassesTeaser
                masterclassesTeaser={contentSection as IDatoCMSMasterclassesTeaser}
                key={contentSection.id}
                id={contentSection.id}
              />
            )
          case ModelApiKey.MASTERCLASSES_CATEGORY_TEASER:
            return (
              <MasterclassesCategoryTeaser
                masterclassesCategoryTeaser={contentSection as IDatoCMSMasterclassesCategoryTeaser}
                key={contentSection.id}
                id={contentSection.id}
              />
            )

          case ModelApiKey.TOP_X_LIST:
            const topXListData = mapTopXList(contentSection as IDatoCMSTopXList, contentSections)
            return <TopXList {...topXListData} key={contentSection.id} />

          case ModelApiKey.IMAGE_GALLERY:
            const imageGallerySection = mapImageGallerySection(
              contentSection as IDatoCMSImageGallery
            )
            return (
              <ImageGallery
                headline={imageGallerySection.headline}
                key={imageGallerySection.id}
                id={imageGallerySection.id}
                images={imageGallerySection.images}
              />
            )
          case ModelApiKey.INSTAGRAM_STORIES_GALLERY:
            const instagramStoriesSection = mapImageGallerySection(
              contentSection as IDatoCMSImageGallery
            )
            return (
              <InstagramStoriesGallery
                {...instagramStoriesSection}
                key={instagramStoriesSection.id}
              />
            )
          case ModelApiKey.ONE_IMAGE:
            const oneImage = mapOneImageBlock(contentSection as IDatoCMSOneImage)
            return <OneImage {...oneImage} key={contentSection.id} />

          case ModelApiKey.CALL_TO_ACTION_SECTION:
            const callToActionSection = mapCallToActionSection(
              contentSection as IDatoCMSCallToActionSection,
              contentSections
            )
            return <CallToActionSection {...callToActionSection} key={callToActionSection.id} />
          case ModelApiKey.BUNDLE_TEASER:
            return (
              <BundleTeaser
                bundleTeaserRecord={contentSection as IDatoCMSBundleTeaserRecord}
                productSnippetsBySlug={productSnippetsBySlug}
                key={contentSection.id}
              />
            )

          case ModelApiKey.HERO_PRODUCT:
            const heroData = mapPersonalizedHeroProduct(
              contentSection as IDatoCMSHeroProduct,
              sleepCheckResults
            )
            return (
              <section
                id={heroData.id}
                className="bg-white lg:bg-transparent section-content-width section-spacing"
                key={contentSection.id}>
                <HeroProduct productSnippetsBySlug={productSnippetsBySlug} heroProduct={heroData} />
              </section>
            )

          case ModelApiKey.SLEEP_SCORE:
            const sleepScore = mapSleepScoreSection(contentSection as IDatoCMSSleepScore)
            return <DynamicSleepScore {...sleepScore} key={sleepScore.id} />

          case ModelApiKey.PERSONALIZABLE_TIP_BLOCK:
            const personalizedTipBlock = mapPersonalizedSleepTipList(
              contentSection as unknown as IDatoCMSPersonalizedSleepTipList,
              sleepCheckResults
            )
            return <PersonalizedSleepTipList key={contentSection.id} {...personalizedTipBlock} />

          case ModelApiKey.PERSONALIZABLE_POSTS_TEASER:
            const personalizedPostsTeaser = mapPersonalizedPostsTeaser(
              contentSection as unknown as IDatoCMSPersonalizedPostsTeaser,
              sleepCheckResults
            )
            return (
              personalizedPostsTeaser && (
                <PostsTeaser key={contentSection.id} {...personalizedPostsTeaser} />
              )
            )

          case ModelApiKey.PERSONALIZABLE_TEXT_PRODUCT_AND_POST_ELEMENT:
            const personalizedTextProductAndPost = mapPersonalizedTextProductAndPost(
              contentSection as unknown as IDatoCMSPersonalizableTextProductAndPost,
              productSnippetsBySlug,
              sleepCheckResults
            )
            return (
              personalizedTextProductAndPost && (
                <PersonalizedTextWithProductListOrPostList
                  key={contentSection.id}
                  {...personalizedTextProductAndPost}
                />
              )
            )

          case ModelApiKey.PERSONALIZABLE_HERO_PRODUCT_LIST:
            const personalizedHeroProductList = mapPersonalizedHeroProductList(
              contentSection as unknown as IDatoCMSPersonalizableHeroProductList,
              productSnippetsBySlug,
              sleepCheckResults
            )
            return (
              personalizedHeroProductList && (
                <PersonalizedHeroProductList
                  key={contentSection.id}
                  {...personalizedHeroProductList}
                />
              )
            )
          case ModelApiKey.STEP_DIVIDER:
            let divider = null
            // first step divider should not render, because it is static above the stage section
            if (stepDividerIndex !== 0) {
              divider = (
                <StepDivider
                  key={contentSection.id}
                  step={steps[stepDividerIndex]}
                  bottomId={steps[stepDividerIndex - 1].id}
                />
              )
            } else {
              divider = null
            }
            stepDividerIndex++
            return divider

          case ModelApiKey.JOURNEY:
            const journey = mapJourney(contentSection as IDatoCMSJourney)
            return <Journey key={contentSection.id} {...journey} />

          case ModelApiKey.PRICING_TABLE:
            const pricingTable = contentSection as IDatoCMSPricingTable
            return <StandardCoachingPricing key={contentSection.id} pricingTable={pricingTable} />

          case ModelApiKey.PRICING_COMPARISON:
            return <PricingComparisonTable key={contentSection.id} id={contentSection.id} />

          case ModelApiKey.COMPARISON_TABLE:
            return <ComparisonTable key={contentSection.id} />

          case ModelApiKey.GPT_ASSESSMENT:
            return (
              <GptAssessment key={contentSection.id} headline={(contentSection as any).headline} />
            )

          default:
            throw new Error(`Component for _modelApiKey: ${modelApiKey} not implemented yet!`)
        }
      })}
    </>
  )
}

export default ContentSectionList
