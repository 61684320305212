import React from 'react'
import Link from 'next/link'
import Image from 'next/image'

import { IDatoCMSSocialMedia } from '@lib/datocms/types/contentSections'

interface ISocialMedia {
  socialMedia: IDatoCMSSocialMedia
  id: string
}

const SocialMedia: React.FC<ISocialMedia> = ({ socialMedia, id }) => (
  <section id={id} className="section-spacing section-content-width">
    <div className="flex flex-col w-full items-center bg-gray-200 section-spacing rounded-tl-xl rounded-br-xl md:flex-row">
      <div className="flex w-full lg:w-1/2 items-center px-4 lg:px-8">
        <div className="max-w-xl w-full">
          <h2 className="overline-section mb-2 md:mb-4">{socialMedia.overline}</h2>
          <h3 className="headline-section mb-8 md:mb-12">{socialMedia.headline}</h3>
        </div>
      </div>
      <div className="w-full lg:w-1/2 px-2 lg:px-8">
        <Link legacyBehavior href={socialMedia.facebookUrl}>
          <a className="btn btn--transparent m-2">
            <Image
              src="/images/icons/icon_socialmedia_facebook_black.svg"
              width="24"
              height="24"
              alt="Instagram Icon"
            />
            <span>Auf Facebook</span>
          </a>
        </Link>
        <Link legacyBehavior href={socialMedia.instagramUrl}>
          <a className="btn btn--transparent m-2">
            <Image
              src="/images/icons/icon_socialmedia_instagram_black.svg"
              width="24"
              height="24"
              alt="Instagram Icon"
            />
            <span>Auf Instagram</span>
          </a>
        </Link>
      </div>
    </div>
  </section>
)

export default SocialMedia
