import React from 'react'
import { motion } from 'framer-motion'
import CheckCircleIcon from '@components/icons/CheckCircleIcon'
import { IDatoCMSBenefitListItem } from '@lib/datocms/types'

const BenefitListItem: React.FC<IDatoCMSBenefitListItem> = ({ headline, text }) => (
  <motion.div
    initial={{ opacity: 0.3 }}
    whileInView={{ opacity: 1 }}
    viewport={{ margin: '0% 0% -45% 0%' }}
    className="flex max-w-xl text-base my-6">
    <div className="mr-4">
      <CheckCircleIcon />
    </div>
    <div>
      <h4 className="font-bold">{headline}</h4>
      <div
        className="text-content-section intro-sections-list"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  </motion.div>
)

export default BenefitListItem
