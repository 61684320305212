import React from 'react'

import Carousel from '@components/Carousel'
import { PersonalizedHeroProductListProps } from '@lib/datocms/types/personalizedContent/heroProductList'
import HeroProduct from '@components/content/contentSection/HeroProduct'

const PersonalizedHeroProductList = ({
  id,
  headline,
  subheadline,
  personalizedHeroProductList,
  productSnippetsBySlug
}: PersonalizedHeroProductListProps) => {
  const heroProducts = personalizedHeroProductList.map((personalizedHeroProduct, index) => (
    <HeroProduct
      heroProduct={personalizedHeroProduct}
      productSnippetsBySlug={productSnippetsBySlug}
      positionIndicator={`${index + 1}/${personalizedHeroProductList.length}`}
    />
  ))

  if (!personalizedHeroProductList.length) return null

  return (
    <section id={id} className="section-spacing section-content-width">
      {!!headline && <h2 className="headline-section mb-4 lg:mb-6">{headline}</h2>}
      {!!subheadline && <h3 className="overline-section mb-4">{subheadline}</h3>}
      <Carousel
        id={`carousel-${id}`}
        carouselItems={heroProducts}
        slidesToShowMobile={1}
        width="fullOnDesktop"
        slidesToShowDesktop={1}
      />
    </section>
  )
}
export default PersonalizedHeroProductList
