import { IPost, IPostIndexPage } from '@modules/posts/types'
import { IDatoCMSPost, IDatoCMSPostsPage } from '../types/post'
import { getInternalLink } from './contentSections'
import { mapImageOrVideo, mapSeoTags } from './misc'

export const mapPostIndexPage = (data: IDatoCMSPostsPage): IPostIndexPage => ({
  contentSections: data.contentSections,
  stageSection: data.stageSection[0],
  seoTags: mapSeoTags(data.seoTags)
})

export const mapPost = (data: IDatoCMSPost): IPost => {
  if (!data) {
    return undefined
  }
  const { id, name, images, slug, categories, shortDescription } = data.author
  return {
    ...data,
    seoTags: mapSeoTags(data.seoTags),
    author: {
      id,
      slug,
      name,
      image: mapImageOrVideo(images[0]),
      shortDescription,
      categories: categories.map((category) => ({
        id: category.id || null,
        name: category.name,
        link: getInternalLink(category._modelApiKey, category.slug) || null
      }))
    }
  }
}
