import React from 'react'

import Link from 'next/link'
import Image from 'next/image'
import ArrowToRightIcon from '@components/icons/ArrowToRightIcon'
import VideoModal from '@components/VideoModal'
import { getLinkFromCallToAction, isVideoLandscape, isVideoPortrait } from '@modules/common/utils'
import VideoPlayer from '@components/VideoPlayer'
import { IDatoCMSFounderStory } from '@lib/datocms/types/contentSections'

interface IFounderStory {
  founderStory: IDatoCMSFounderStory
  id: string
}

const FounderStory: React.FC<IFounderStory> = ({ founderStory }) => (
  <section id={founderStory.id} className="section-spacing section-content-width">
    <div className="flex flex-wrap w-full bg-gray-200 section-spacing px-4 md:items-center lg:px-8">
      <div className="flex flex-wrap w-full md:w-1/2 items-center md:px-4 lg:px-8">
        <div className="relative rounded-full mx-auto md:mx-0 h-48 w-48 lg:h-56 lg:w-56 md:-ml-2 mb-12 -mt-24 lg:-mt-32">
          <Image
            alt={founderStory.founderImage.responsiveImage.alt || founderStory.founderImage.alt}
            width={founderStory.founderImage.responsiveImage.width}
            height={founderStory.founderImage.responsiveImage.height}
            src={founderStory.founderImage.responsiveImage.src}
            className="rounded-full"
            sizes="(min-width: 767px) 20vw, 50vw"
          />
          <div className="absolute z-10 bottom-0 right-0 rounded-full bg-gray-200 p-1 h-12 w-12 mr-2">
            <Image alt="yoself Icon" width="50" height="50" src="/images/yoself_icon_black.png" />
          </div>
        </div>
        <div className="max-w-xl w-full">
          <h2 className="overline-section mb-2 md:mb-4">{founderStory.overline}</h2>
          <h3 className="headline-section mb-8 md:mb-12">{founderStory.headline}</h3>

          <div
            className="text-content-section text-sections-list font-light mt-4 mb-10 md:mt-8"
            dangerouslySetInnerHTML={{ __html: founderStory.quote }}
          />

          {founderStory.callToAction && founderStory.callToAction[0] && (
            <Link legacyBehavior href={getLinkFromCallToAction(founderStory.callToAction[0])}>
              <a className="btn btn--black">
                <ArrowToRightIcon />
                <span>{founderStory.callToAction[0].buttonText}</span>
              </a>
            </Link>
          )}
        </div>
      </div>
      <div className="relative flex w-full md:w-1/2 items-center mt-12 my-6 md:mt-0 md:px-4 lg:px-8 justify-end">
        {isVideoPortrait(founderStory.video) && <VideoModal video={founderStory.video} />}
        {isVideoLandscape(founderStory.video) && <VideoPlayer video={founderStory.video} />}
      </div>
    </div>
  </section>
)

export default FounderStory
