import React from 'react'
import Image from 'next/image'

import { IDatoCMSTestimonialOverview } from '@lib/datocms/types/contentSections'
import Carousel from '@components/Carousel'
import Stars from '@components/Stars'

interface ITestimonialOverview {
  testimonialOverview: IDatoCMSTestimonialOverview
  id: string
}

const TestimonialOverview: React.FC<ITestimonialOverview> = ({ testimonialOverview, id }) => {
  const carouselItems: JSX.Element[] = testimonialOverview.testimonialList.map((listItem) => (
    <div className="flex flex-wrap sm:flex-nowrap w-full">
      <div className="w-40 h-40 xl:w-52 xl:h-52 mb-4">
        <Image
          className="md:group-hover:scale-105 transform transition-all rounded-full"
          src={listItem.image.responsiveImage.src}
          alt={listItem.image.alt || 'Testimonial'}
          width={listItem.image.responsiveImage.width}
          height={listItem.image.responsiveImage.height}
          sizes="(min-width: 767px) 155px, 50vw"
        />
      </div>

      <div className="w-full sm:px-4 md:px-8">
        <h3 className="font-bold text-base md:text-lg w-full group-hover:underline mb-3">
          {listItem.name}
        </h3>
        <div className="pb-4">
          <Stars score={5} />
        </div>
        <blockquote className="non-bold-blockquote text-base">{listItem.quote}</blockquote>
      </div>
    </div>
  ))

  return (
    <section id={id} className="section-spacing section-content-width">
      <h2 className="overline-section mb-8 md:mb-10">{testimonialOverview.headline}</h2>
      <Carousel
        id={id}
        carouselItems={carouselItems}
        slidesToShowMobile={1}
        slidesToShowDesktop={2}
        width="trimmed"
      />
    </section>
  )
}

export default TestimonialOverview
