import React from 'react'
import Link from 'next/link'
import Image from 'next/image'

import { IDatoCMSTopicOverview, IDatoCMSTopicTeaser } from '@lib/datocms/types/contentSections'
import ArrowToRightIcon from '@components/icons/ArrowToRightIcon'
import Carousel from '@components/Carousel'
import { getLinkFromCallToAction } from '@modules/common/utils'

interface ITopicOverview {
  topicOverview: IDatoCMSTopicOverview
  id: string
}

const TopicOverview: React.FC<ITopicOverview> = ({ topicOverview, id }) => {
  const carouselItems = topicOverview.topicList.map((topic: IDatoCMSTopicTeaser) => (
    <Link legacyBehavior href={getLinkFromCallToAction(topic.callToAction[0])} key={topic.id}>
      <a className="group">
        <div className="bg-gray-200 group-hover:bg-gray-300">
          <Image
            className="md:group-hover:scale-105 transform transition-all"
            src={topic.image.responsiveImage.src}
            alt={topic.image.alt}
            width={topic.image.responsiveImage.width}
            height={topic.image.responsiveImage.height}
            sizes="(max-width: 767px) 100vw, 60vw"
          />
        </div>
        <div className="my-4">
          <h3 className="font-bold text-base md:text-lg w-full group-hover:underline mb-2">
            {topic.headline}
          </h3>
          <p className="text-base">{topic.text}</p>
        </div>
        <div className="btn btn--blank">
          <ArrowToRightIcon />
          <span className="group-hover:underline">{topic.callToAction[0].buttonText}</span>
        </div>
      </a>
    </Link>
  ))

  return (
    <section id={id} className="section-spacing section-content-width">
      <h2 className="overline-section mb-8">{topicOverview.headline}</h2>

      <Carousel
        id={id}
        carouselItems={carouselItems}
        slidesToShowMobile={1}
        slidesToShowDesktop={4}
        width="trimmed"
      />
    </section>
  )
}

export default TopicOverview
