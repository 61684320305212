import React from 'react'
import Image from 'next/image'
import { IDatoCMSFeaturedIn } from '@lib/datocms/types'

const FeaturedIn = ({ logos, id, headline }: IDatoCMSFeaturedIn) => {
  if (!logos) return null
  return (
    <div id={id} className="flex flex-col items-center justify-center w-full p-4 mb-4 md:mb-16 ">
      <h4 className="text-gray-400 mb-4 md:mb-8 mt-4 text-lg font-bold">
        {headline || 'Bekannt Aus'}
      </h4>
      <div className="flex flex-wrap justify-center w-full px-4 ">
        {logos.map((image) => (
          <div key={image.responsiveImage.src} className="my-2 mx-4 md:mx-6">
            <Image
              alt={image.alt || 'Logo'}
              src={image.responsiveImage.src}
              width={image.responsiveImage.width / 1.8}
              height={image.responsiveImage.height / 2}
              sizes="(max-width: 767px) 40vw, (max-width: 1280px) 15vw, 10vw"
            />
          </div>
        ))}
      </div>
    </div>
  )
}
export default FeaturedIn
