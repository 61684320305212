import Image from 'next/image'
import React from 'react'

import { IDatoCMSIconsWithTextListItem } from '@lib/datocms/types/contentSections'

type IconsWithTextCardProps = {
  iconsWithTextCard: IDatoCMSIconsWithTextListItem
}

const IconsWithTextCard = ({ iconsWithTextCard }: IconsWithTextCardProps) => (
  <div
    className="flex flex-col max-w-content-sm bg-gray-200 h-full mt-16 px-4 pt-8 pb-6 lg:px-6 lg:pt-14 lg:pb-12"
    key={iconsWithTextCard.id}>
    <div className="-mt-20 lg:-mt-28 -xl:-mt-32 w-24">
      <div className="aspect-w-1 aspect-h-1 bg-white rounded-full">
        {iconsWithTextCard.icon && (
          <Image
            alt={iconsWithTextCard.icon.alt || 'Icon'}
            src={iconsWithTextCard.icon.responsiveImage?.src || iconsWithTextCard.icon.url}
            width="100"
            height="100"
            className="rounded-full object-cover"
            sizes="(min-width: 767px) 50vw, 10vw"
          />
        )}
        {iconsWithTextCard.customTextIcon && (
          <div className="text-4xl font-semibold flex border-4 border-gray-200 justify-center items-center rounded-full">
            <span>{iconsWithTextCard.customTextIcon}</span>
          </div>
        )}
      </div>
    </div>
    <div className="flex flex-col text-content-section text-sections-list">
      <h4 className="text-base md:text-lg font-bold mt-6 mb-4">{iconsWithTextCard.itemName}</h4>
      <p>{iconsWithTextCard.text}</p>
    </div>
  </div>
)

export default IconsWithTextCard

//  text-white bg-black
