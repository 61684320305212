import React from 'react'
import Link from 'next/link'
import Image from 'next/image'

import ArrowToRightIcon from '@components/icons/ArrowToRightIcon'
import { IDatoCMSMasterclassesTeaser } from '@lib/datocms/types/contentSections'
import VideoModal from '@components/VideoModal'
import { getLinkFromCallToAction, isVideoLandscape, isVideoPortrait } from '@modules/common/utils'
import VideoPlayer from '@components/VideoPlayer'
import MasterclassesIcon from '@components/icons/MasterclassesIcon'

interface IMasterclassesTeaser {
  masterclassesTeaser: IDatoCMSMasterclassesTeaser
  id: string
}

const FounderStory: React.FC<IMasterclassesTeaser> = ({ masterclassesTeaser, id }) => {
  const image = masterclassesTeaser.imageOrVideo.responsiveImage
  const { video } = masterclassesTeaser.imageOrVideo

  return (
    <section
      id={id}
      className="section-spacing section-content-width flex flex-col md:flex-row-reverse">
      <div className="-mx-4 relative leading-zero md:mx-auto md:w-2/3">
        {image && (
          <Image
            alt={masterclassesTeaser.headline}
            src={image.src}
            width={image.width}
            height={image.height}
            sizes="(min-width: 767px) 66vw, 100vw"
          />
        )}
        {video && isVideoPortrait(masterclassesTeaser.imageOrVideo) && (
          <VideoModal video={masterclassesTeaser.imageOrVideo} posterFormat="4:3" />
        )}
        {video && isVideoLandscape(masterclassesTeaser.imageOrVideo) && (
          <VideoPlayer video={masterclassesTeaser.imageOrVideo} />
        )}
        <div className="absolute w-28 bottom-0 right-4 md:w-32 translate-y-1/2 md:translate-y-0 md:top-6 md:bottom-initial md:right-8">
          <MasterclassesIcon />
        </div>
      </div>
      <div className="-mx-4 flex flex-col justify-center items-start bg-gray-200 section-content-width py-12 md:w-1/3 md:m-0">
        <h2 className="overline-section mb-4">{masterclassesTeaser.overline}</h2>
        <h3 className="headline-section mb-6">{masterclassesTeaser.headline}</h3>
        <div
          className="text-content-section text-sections-list font-light mb-6"
          dangerouslySetInnerHTML={{ __html: masterclassesTeaser.text }}
        />
        {masterclassesTeaser.callToAction && masterclassesTeaser.callToAction[0] && (
          <Link legacyBehavior href={getLinkFromCallToAction(masterclassesTeaser.callToAction[0])}>
            <a className="btn btn--black">
              <ArrowToRightIcon />
              <span>{masterclassesTeaser.callToAction[0].buttonText}</span>
            </a>
          </Link>
        )}
      </div>
    </section>
  )
}

export default FounderStory
