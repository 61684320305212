import React from 'react'
import Image from 'next/image'
import { IDatoCMSProduct } from '@lib/datocms/types/product'
import Product from '@components/content/contentSection/Products/Product'
import Carousel from '@components/Carousel'
import { getDatoIndexKey } from '@lib/productService/utils'
import VideoPlayer from '@components/VideoPlayer'
import { isVideoLandscape, isVideoPortrait } from '@modules/common/utils'
import VideoModal from '@components/VideoModal'
import { ThematicRecommendationProps } from '@modules/contentSections/types'
import { IProductSnippetsBySlug } from '@modules/products/types'
import { useIntersectionObserverForTracking } from 'hooks/useIntersectionObserver'
import trackViewItemListEvent from '@lib/tracking/trackViewItemListEvent'

type ThematicRecommendationPropsWithSlugs = ThematicRecommendationProps & {
  productSnippetsBySlug: IProductSnippetsBySlug
}
const ThematicRecommendation = ({
  id,
  headline,
  overline,
  text,
  image,
  products,
  productSnippetsBySlug
}: ThematicRecommendationPropsWithSlugs) => {
  const carouselItems = products.map((datoCMSProduct: IDatoCMSProduct) => {
    const product = productSnippetsBySlug[getDatoIndexKey(datoCMSProduct)]

    return (
      <Product
        key={datoCMSProduct.id}
        product={product}
        imageSizes="(min-width: 767px) 25vw, 100vw"
      />
    )
  })

  const productsForTracking = products.map(
    (product) => productSnippetsBySlug[getDatoIndexKey(product)]
  )

  const { ref } = useIntersectionObserverForTracking({
    action: () => trackViewItemListEvent(id, headline, productsForTracking),
    threshold: 0.2
  })

  return (
    <section id={id} className="section-spacing section-content-width" ref={ref}>
      <div className="flex flex-wrap column md:row pb-8">
        <div className="w-full md:w-1/2 md:pr-4">
          {image.responsiveImage && (
            <Image
              alt={image.alt || 'Thematic Recommendation'}
              width={image.responsiveImage.width}
              height={image.responsiveImage.height}
              src={image.responsiveImage.src}
              sizes="(min-width: 767px) 50vw, 100vw"
            />
          )}
          {image.video && isVideoLandscape(image) && (
            <div className="max-h-[28rem] h-full">
              <VideoPlayer video={image} />
            </div>
          )}
          {image.video && isVideoPortrait(image) && <VideoModal video={image} posterFormat="4:3" />}
        </div>

        <div className="w-full md:w-1/2 flex items-center justify-center md:pl-12 pr-4">
          <div className="max-w-xl">
            <h2 className="overline-section mt-8 md:mt-0 mb-2 md:mb-4">{overline}</h2>
            <h3 className="headline-section">{headline}</h3>

            <div className="text-content-section text-sections-list">
              <div className="text-base mt-4 md:pb-6" dangerouslySetInnerHTML={{ __html: text }} />
            </div>
          </div>
        </div>
      </div>
      <div>
        <Carousel
          id={id}
          carouselItems={carouselItems}
          slidesToShowMobile={1}
          slidesToShowDesktop={4}
          width="trimmed"
          showAllOnDesktop
        />
      </div>
    </section>
  )
}

export default ThematicRecommendation
