import React from 'react'

import { IDatoCMSIconsWithText } from '@lib/datocms/types/contentSections'
import Carousel from '@components/Carousel'
import IconsWithTextCard from './IconsWithTextCard'

interface IIconsWithText {
  iconsWithText: IDatoCMSIconsWithText
  id: string
}

const IconsWithText: React.FC<IIconsWithText> = ({ iconsWithText, id }) => {
  const carouselIconItems = iconsWithText.iconsTextList.map((listItem) => (
    <IconsWithTextCard iconsWithTextCard={listItem} key={listItem.id} />
  ))

  return (
    <section id={id} className="bg-white section-content-width section-spacing">
      {iconsWithText.headline && (
        <h3 className="overline-section mb-4">{iconsWithText.headline}</h3>
      )}
      <div className="mb-8">
        <Carousel
          id={id}
          carouselItems={carouselIconItems}
          slidesToShowMobile={1}
          slidesToShowDesktop={4}
          width="trimmed"
        />
      </div>
    </section>
  )
}
export default IconsWithText
