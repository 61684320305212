import React from 'react'
import Link from 'next/link'
import Image from 'next/image'

import Carousel from '@components/Carousel'
import Product from '@components/content/contentSection/Products/Product'
import {
  PersonalizedTextProductAndPostContent,
  PersonalizedTextProductAndPostProps
} from '@modules/contentSections/types'
import ArrowToRightIcon from '@components/icons/ArrowToRightIcon'

const PersonalizedTextWithProductListOrPostList = ({
  id,
  headline,
  subheadline,
  personalizedTextProductAndPostContentList
}: PersonalizedTextProductAndPostProps) => {
  const productCarouselItems = (
    personalizedTextProductAndPost: PersonalizedTextProductAndPostContent
  ) =>
    personalizedTextProductAndPost.productSnippets.map((snippet) => (
      <Product
        key={`${personalizedTextProductAndPost.id}_${snippet.shopifyProductId}`}
        product={snippet}
        imageSizes="(min-width: 767px) 25vw, 50vw"
      />
    ))

  const postCarouselItems = (
    personalizedTextProductAndPost: PersonalizedTextProductAndPostContent
  ) =>
    personalizedTextProductAndPost.postTeasers.map((post) => (
      <Link href={`/posts/${post.slug}`} key={post.id} className="mb-4 inline-block w-full">
        <Image
          className="w-full mb-4"
          src={post.mainImage.src}
          alt={post.mainImage.alt || ''}
          width={400}
          height={300}
          sizes="(min-width: 767px) 25vw, 50vw"
        />
        <p className="text-xs">{post.author.name}</p>
        <h3 className="font-bold text-sm md:text-base">{post.headline}</h3>
        <div className="btn btn--blank">
          <ArrowToRightIcon />
          <span>MEHR</span>
        </div>
      </Link>
    ))

  return (
    <section id={id} className="section-content-width my-2">
      <div className="max-w-content-sm md:m-auto">
        {headline && <h2 className="headline-section mb-6 pt-12 md:pt-20">{headline}</h2>}
        {subheadline && <h3 className="overline-section mb-4">{subheadline}</h3>}
        {personalizedTextProductAndPostContentList.map(
          (personalizedTextProductAndPost) =>
            personalizedTextProductAndPost.text && ( // hide default variant if no text is set
              <div key={personalizedTextProductAndPost.id} className="mb-4">
                <h3 className="text-base font-bold mb-4">
                  {personalizedTextProductAndPost.subheadline}
                </h3>
                <div
                  className="mb-3 text-content-section text-sections-list"
                  dangerouslySetInnerHTML={{ __html: personalizedTextProductAndPost.text }}
                />
                {!!personalizedTextProductAndPost.productSnippets.length && (
                  <Carousel
                    id={personalizedTextProductAndPost.id}
                    carouselItems={productCarouselItems(personalizedTextProductAndPost)}
                    slidesToShowMobile={2}
                    slidesToShowDesktop={3}
                    width="trimmed"
                  />
                )}
                {!!personalizedTextProductAndPost.postTeasers.length && (
                  <Carousel
                    id={personalizedTextProductAndPost.id}
                    carouselItems={postCarouselItems(personalizedTextProductAndPost)}
                    slidesToShowMobile={2}
                    slidesToShowDesktop={3}
                    width="trimmed"
                  />
                )}
              </div>
            )
        )}
      </div>
    </section>
  )
}
export default PersonalizedTextWithProductListOrPostList
