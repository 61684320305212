import React from 'react'

import { IContentSection } from '@modules/contentSections/types'
import Carousel from '@components/Carousel'
import Category, { CategoryProps } from './Categories/Category'

export type CategoriesProps = IContentSection & {
  id: string
  headline: string
  categories: CategoryProps[]
}

const Categories = ({ id, headline, categories }: CategoriesProps) => {
  if (!categories) return null
  const categoriesList = categories.map((category: CategoryProps) => <Category {...category} />)

  return (
    <section id={id} className="section-content-width section-spacing">
      <h2 className="overline-section">{headline}</h2>

      <Carousel
        id={id}
        carouselItems={categoriesList}
        slidesToShowMobile={1}
        slidesToShowDesktop={3}
        width="trimmed"
      />
    </section>
  )
}

export default Categories
