import React, { useEffect, useRef } from 'react'
import Image from 'next/image'
import { motion, useAnimation, useInView } from 'framer-motion'
import classNames from 'classnames'

import { IJourneyStep } from '@modules/journey/types'

type JourneyStepProps = {
  index: number
  stepOverline: string
  step: IJourneyStep
}

const imageVariants = {
  visible: { opacity: 1, scale: 1, transition: { ease: 'easeInOut', duration: 0.7 } },
  hidden: { opacity: 1, scale: 0.8 }
}

const textVariants = {
  visible: { opacity: 1, x: 0, transition: { ease: 'easeInOut', duration: 0.7, delay: 0.5 } },
  left: { opacity: 0, x: -300 },
  right: { opacity: 0, x: 300 }
}

const JourneyStep = ({ index, step, stepOverline }: JourneyStepProps) => {
  const isOdd = (num: number) => num % 2 === 1
  const imageControls = useAnimation()
  const textControls = useAnimation()
  const imageRef = useRef<HTMLDivElement>(null)
  const textRef = useRef<HTMLDivElement>(null)
  const imageInView = useInView(imageRef)
  const textInView = useInView(textRef)

  useEffect(() => {
    if (imageInView) {
      imageControls.start('visible')
    }
  }, [imageInView])
  useEffect(() => {
    if (textInView) {
      textControls.start('visible')
    }
  }, [textInView])

  return (
    <div
      className={classNames(
        'flex flex-col justify-center max-lg:bg-journey-gradient py-8 my-10 overflow-hidden',
        {
          'md:flex-row': !isOdd(index),
          'md:flex-row-reverse': isOdd(index)
        }
      )}
      key={step.id}>
      <motion.div
        ref={imageRef}
        animate={imageControls}
        initial="hidden"
        variants={imageVariants}
        className={classNames('mb-4 md:w-1/2 max-w-full md:max-w-xl', {
          'md:pr-24': !isOdd(index),
          'md:pl-24': isOdd(index)
        })}>
        <Image
          className="w-full"
          src={step.image.url}
          alt={step.image.alt || `Schritt ${index}`}
          width={step.image.width}
          height={step.image.height}
        />
      </motion.div>
      <motion.div
        ref={textRef}
        animate={textControls}
        initial={isOdd(index) ? 'left' : 'right'}
        variants={textVariants}
        className={classNames('md:w-1/2 max-w-xl', {
          'md:pl-24': !isOdd(index),
          'md:pr-24': isOdd(index)
        })}>
        <h3 className="overline-section mb-1">{stepOverline}</h3>
        <h4 className="headline-section mb-2">{step.headline}</h4>
        <p className="">{step.text}</p>
      </motion.div>
    </div>
  )
}

export default JourneyStep
