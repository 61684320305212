import React from 'react'
import Image from 'next/image'

import logo from 'images/logo/yoself-icon-lightgray.svg'

export type DividerProps = {
  dividerType: string
}

const DividerWithIcon = ({ dividerType = 'line_with_icon' }: DividerProps) => (
  <div className="section-content-width relative my-8">
    {dividerType === 'line_with_icon' && (
      <Image
        src={logo}
        alt="y"
        width="32"
        height="32"
        className="absolute left-1/2 right-1/2 -translate-y-1/2 -translate-x-1/2"
      />
    )}
    <hr className="border-1 border-gray-300 mt-6" />
  </div>
)

export default DividerWithIcon
