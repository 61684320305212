import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { useRouter } from 'next/router'

import ArrowToRightIcon from '@components/icons/ArrowToRightIcon'
import VideoPlayer from '@components/VideoPlayer'
import { IDatoCMSEditorialRecord } from '@lib/datocms/types'

interface IEditoral {
  editorialRecord: IDatoCMSEditorialRecord
  id: string
}

const Editorial: React.FC<IEditoral> = ({ editorialRecord, id }) => {
  const reverse = editorialRecord.imagePosition === 'right'
  const isRoot = useRouter().route === '/'

  return (
    <section id={id} className="bg-white section-content-width section-spacing">
      <div
        className={`flex flex-col item-start md:items-center md:flex-row ${
          reverse ? 'md:flex-row-reverse md:justify-end' : ''
        }`}>
        <div className={`w-full md:w-1/2 ${reverse ? 'md:pl-3' : 'md:pr-3'}`}>
          {editorialRecord.post.mainImageVideo.responsiveImage && (
            <Image
              alt={
                editorialRecord.post.mainImageVideo.alt ||
                `Editorial-${editorialRecord.post.headline}`
              }
              width={editorialRecord.post.mainImageVideo.responsiveImage.width}
              height={editorialRecord.post.mainImageVideo.responsiveImage.height}
              src={editorialRecord.post.mainImageVideo.responsiveImage.src}
              sizes="(min-width: 767px) 50vw, 100vw"
            />
          )}
          {editorialRecord.post.mainImageVideo.video && (
            <VideoPlayer video={editorialRecord.post.mainImageVideo} />
          )}
        </div>

        <div className={`w-full md:w-1/2 ${reverse ? 'md:pl-0 md:pr-12' : 'md:pl-12 md:pr-3'}`}>
          <h2 className="overline-section mt-8 md:mt-0 mb-2 md:mb-4">{editorialRecord.headline}</h2>

          <h3 className="headline-section mb-8">{editorialRecord.post.headline}</h3>
          {isRoot && <hr className="w-12 border-black border-2 md:mt-16" />}

          <div
            className="text-base pb-8 mt-4 md:pb-10 max-w-xl"
            dangerouslySetInnerHTML={{ __html: editorialRecord.post.summary }}
          />

          <Link legacyBehavior href={`/posts/${editorialRecord.post.slug}`}>
            <a className="btn btn--black">
              <ArrowToRightIcon />
              <span>Read the edit</span>
            </a>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Editorial
