import YesIcon from '@components/content/contentSection/ComparisonTable/YesIcon'
import PerhapsIcon from '@components/content/contentSection/ComparisonTable/PerhapsIcon'
import NoIcon from '@components/content/contentSection/ComparisonTable/NoIcon'

const ComparisonTable = () => (
  <>
    <h3 className="headline-section section-content-width mt-8">
      Das yoself Sleep Coaching im Vergleich
    </h3>
    <section className="section-content-width section-spacing max-w-full overflow-auto text-sm lg:text-base">
      <table className="table-auto m-auto text-left">
        <thead className="text-center">
          <tr className="text-[8px]">
            <th>&nbsp;</th>
            <th className="bg-sky-200 rounded-t">&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
          </tr>
          <tr>
            <th>&nbsp;</th>
            <th className="bg-sky-200 px-2">
              yoself
              <br />
              Sleep Coaching
            </th>
            <th className="bg-black text-white p-2">Psychologen</th>
            <th className="bg-black text-white p-2">Schlafklinik</th>
            <th className="bg-black text-white p-2">Schlaftracker</th>
            <th className="bg-black text-white p-2">(Habit-)Apps</th>
            <th className="bg-black text-white p-2 rounded-r">Schlafmittel</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border-black border-b p-2">Wirksamkeit</td>
            <td className="bg-sky-200 border-sky-200 border-b">
              <YesIcon />
            </td>
            <td className="border-black border-b p-2">
              <YesIcon />
            </td>
            <td className="border-black border-b p-2">
              <YesIcon />
            </td>
            <td className="border-black border-b p-2">
              <PerhapsIcon />
            </td>
            <td className="border-black border-b p-2">
              <PerhapsIcon />
            </td>
            <td className="border-black border-b p-2">
              <NoIcon />
            </td>
          </tr>
          <tr>
            <td className="border-black border-b p-2">Flexibilität</td>
            <td className="bg-sky-200 border-sky-200 border-b">
              <YesIcon />
            </td>
            <td className="border-black border-b p-2">
              <NoIcon />
            </td>
            <td className="border-black border-b p-2">
              <PerhapsIcon />
            </td>
            <td className="border-black border-b p-2">
              <YesIcon />
            </td>
            <td className="border-black border-b p-2">
              <YesIcon />
            </td>
            <td className="border-black border-b p-2">
              <NoIcon />
            </td>
          </tr>
          <tr>
            <td className="border-black border-b p-2">Ohne Neben-Wirkungen</td>
            <td className="bg-sky-200 border-sky-200 border-b">
              <YesIcon />
            </td>
            <td className="border-black border-b p-2">
              <YesIcon />
            </td>
            <td className="border-black border-b p-2">
              <YesIcon />
            </td>
            <td className="border-black border-b p-2">
              <YesIcon />
            </td>
            <td className="border-black border-b p-2">
              <YesIcon />
            </td>
            <td className="border-black border-b p-2">
              <NoIcon />
            </td>
          </tr>
          <tr>
            <td className="border-black border-b p-2">Persönliche Beratung</td>
            <td className="bg-sky-200 border-sky-200 border-b">
              <YesIcon />
            </td>
            <td className="border-black border-b p-2">
              <YesIcon />
            </td>
            <td className="border-black border-b p-2">
              <YesIcon />
            </td>
            <td className="border-black border-b p-2">
              <PerhapsIcon />
            </td>
            <td className="border-black border-b p-2">
              <NoIcon />
            </td>
            <td className="border-black border-b p-2">
              <NoIcon />
            </td>
          </tr>
          <tr>
            <td className="border-black border-b p-2">Ständiger Ansprech-Partner</td>
            <td className="bg-sky-200 border-sky-200 border-b">
              <YesIcon />
            </td>
            <td className="border-black border-b p-2">
              <NoIcon />
            </td>
            <td className="border-black border-b p-2">
              <NoIcon />
            </td>
            <td className="border-black border-b p-2">
              <PerhapsIcon />
            </td>
            <td className="border-black border-b p-2">
              <NoIcon />
            </td>
            <td className="border-black border-b p-2">
              <NoIcon />
            </td>
          </tr>
          <tr>
            <td className="border-black border-b p-2">Geringer Aufwand</td>
            <td className="bg-sky-200 border-sky-200 border-b">
              <YesIcon />
            </td>
            <td className="border-black border-b p-2">
              <NoIcon />
            </td>
            <td className="border-black border-b p-2">
              <NoIcon />
            </td>
            <td className="border-black border-b p-2">
              <YesIcon />
            </td>
            <td className="border-black border-b p-2">
              <YesIcon />
            </td>
            <td className="border-black border-b p-2">
              <NoIcon />
            </td>
          </tr>
          <tr>
            <td className="border-black border-b p-2">Langfristige Verbesserung</td>
            <td className="bg-sky-200 border-sky-200 border-b">
              <YesIcon />
            </td>
            <td className="border-black border-b p-2">
              <PerhapsIcon />
            </td>
            <td className="border-black border-b p-2">
              <PerhapsIcon />
            </td>
            <td className="border-black border-b p-2">
              <PerhapsIcon />
            </td>
            <td className="border-black border-b p-2">
              <NoIcon />
            </td>
            <td className="border-black border-b p-2">
              <NoIcon />
            </td>
          </tr>
          <tr>
            <td className="border-black border-b p-2">Zugang zu Fachexperten</td>
            <td className="bg-sky-200 border-sky-200 border-b">
              <YesIcon />
            </td>
            <td className="border-black border-b p-2">
              <NoIcon />
            </td>
            <td className="border-black border-b p-2">
              <YesIcon />
            </td>
            <td className="border-black border-b p-2">
              <NoIcon />
            </td>
            <td className="border-black border-b p-2">
              <NoIcon />
            </td>
            <td className="border-black border-b p-2">
              <NoIcon />
            </td>
          </tr>
          <tr>
            <td className="border-black border-b p-2">Ganzheitlicher Ansatz</td>
            <td className="bg-sky-200 border-sky-200 border-b">
              <YesIcon />
            </td>
            <td className="border-black border-b p-2">
              <NoIcon />
            </td>
            <td className="border-black border-b p-2">
              <NoIcon />
            </td>
            <td className="border-black border-b p-2">
              <NoIcon />
            </td>
            <td className="border-black border-b p-2">
              <NoIcon />
            </td>
            <td className="border-black border-b p-2">
              <NoIcon />
            </td>
          </tr>
          <tr className="text-[8px]">
            <td>&nbsp;</td>
            <td className="bg-sky-200 rounded-b">&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
        </tbody>
      </table>
    </section>
  </>
)

export default ComparisonTable
