import React from 'react'
import Image from 'next/image'
import Link from 'next/link'

import { getExternalOrInternalLink } from '@modules/common/utils'
import { IInternalLink, YoImage } from '@modules/common/types'

export type CategoryProps = {
  name: string
  id: string
  images: YoImage[]
  link: IInternalLink
  text?: string
}

const Category = ({ name, images, link, id, text }: CategoryProps) => {
  const leftImage = images[0]
  const rightImage = images[1]

  return (
    <Link href={getExternalOrInternalLink(link)} id={id}>
      <div className="relative w-full h-full flex flex-col  bg-gray-200 group">
        <div className="flex relative items-center mt-8 md:mt-10">
          <div className="w-1/2 relative">
            <Image
              className="w-full h-full"
              src={leftImage.responsiveImage.src}
              alt={leftImage.alt}
              width={374}
              height={550}
              sizes="(maz-width: 767px) 50vw, (max-width: 1280px) 17vw, 13vw"
            />
          </div>
          <div className="w-1/2 relative">
            <Image
              className="w-full h-full"
              src={rightImage.responsiveImage}
              alt={rightImage.alt}
              width={374}
              height={550}
              sizes="(maz-width: 767px) 50vw, (max-width: 1280px) 17vw, 12vw"
            />
          </div>
          <div className="absolute w-full text-center flex flex-col items-center">
            <div className="btn btn--large mx-auto">{name}</div>
          </div>
        </div>
        <div className="h-full pt-5 pb-4 px-3">
          <p className="text-center text-base font-semibold whitespace-pre-wrap">{text}</p>
        </div>
      </div>
    </Link>
  )
}

export default Category
