import { IBrand, IBrandStageSection } from '@modules/brands/types'
import { getTargetForCta } from '@modules/common/utils'
import { IDatoCMSBrandPage, IDatoCMSBrandStage } from '../types/brand'
import { mapCallToAction, mapImage } from './misc'

export const mapBrandStageSection = (stageData: IDatoCMSBrandStage): IBrandStageSection => ({
  ...stageData,
  stageImage: mapImage(stageData.stageImage),
  brandImage: {
    alt: stageData.brandImage.alt,
    url: stageData.brandImage.url
  },
  text: stageData.headline,
  callToAction: stageData.callToAction[0]
})

export const mapBrandPage = (data: IDatoCMSBrandPage): IBrand => {
  if (!data) {
    return undefined
  }
  const stageSection = data.stageSection[0]
  const hasCallToAction = stageSection.callToAction.length > 0
  const targetId = getTargetForCta(stageSection.callToAction[0], data.contentSections)
  return {
    ...data,
    indexable: data.indexable,
    brandName: data.brandName,
    stageSection: {
      ...mapBrandStageSection(stageSection),
      callToAction: hasCallToAction ? mapCallToAction(stageSection.callToAction[0], targetId) : null
    }
  }
}
