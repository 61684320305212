import React from 'react'

interface TextContentSectionProps {
  id: string
  headline: string
  subheadline: string
  textColumn: string
  textColumnTwo: string
}
const TextContentSection = ({
  id,
  headline,
  subheadline,
  textColumn,
  textColumnTwo
}: TextContentSectionProps) => (
  <section id={id}>
    {/* two collums */}
    {textColumn && textColumnTwo ? (
      <div className="bg-white section-content-width section-spacing ">
        <h2 className="overline-section mb-2 md:mb-4">{headline}</h2>
        <div className="text-content-section text-sections-list md:grid md:grid-cols-2 md:gap-16">
          <div className="flex flex-col">
            <h1>{subheadline}</h1>
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: textColumn
              }}
            />
          </div>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: textColumnTwo
            }}
          />
        </div>
      </div>
    ) : (
      <div className="bg-white section-content-width section-spacing">
        <div className="max-w-content-sm mx-auto">
          <h2 className="headline-section mb-2 md:mb-4">{headline}</h2>
          <div className="text-content-section text-sections-list">
            <h1 className="md:pr-32">{subheadline}</h1>
            <div
              className="text-content-section text-sections-list max-w-content-sm"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: textColumn }}
            />
          </div>
        </div>
      </div>
    )}
  </section>
)

export default TextContentSection
