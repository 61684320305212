import React from 'react'
import Link from 'next/link'
import { ICallToAction } from '@modules/common/types'
import { getLinkFromCallToAction } from '@modules/common/utils'
import { IJourneyStep } from '@modules/journey/types'
import ProgressVector from '@components/content/contentSection/Journey/ProgressVector'
import JourneyStep from '@components/content/contentSection/Journey/JourneyStep'

type JourneyProps = {
  overline: string
  headline: string
  stepOverlineText: string
  journeySteps: IJourneyStep[]
  conclusionHeadline?: string
  conclusionText?: string
  callToAction?: ICallToAction
}

const Journey = ({
  overline,
  headline,
  stepOverlineText,
  journeySteps,
  conclusionHeadline,
  conclusionText,
  callToAction
}: JourneyProps) => (
  <div className="section-content-width">
    <div className="relative z-1 flex flex-col md:items-center">
      <h2 className="overline-section mb-1">{overline}</h2>
      <h3 className="headline-section mb-2">{headline}</h3>
    </div>
    <div className="relative overflow-hidden">
      {journeySteps.map((step, index) => (
        <JourneyStep
          key={step.id}
          index={index}
          step={step}
          stepOverline={`${stepOverlineText + (index + 1)} / ${journeySteps.length}`}
        />
      ))}
      <div className="absolute bottom-0 left-1/2 -translate-x-1/2 -z-1">
        <ProgressVector />
      </div>
      <div className="fixed bottom-0 bg-white h-[30vh] -z-1 w-screen left-0" />
    </div>
    <div className="relative z-1 my-8 max-w-xl md:mx-auto">
      {conclusionHeadline && <h4 className="headline-section mb-2">{conclusionHeadline}</h4>}
      {conclusionText && <p className="">{conclusionText}</p>}
      {callToAction.buttonText && (
        <Link className="btn btn-primary mt-8" href={getLinkFromCallToAction(callToAction)}>
          {callToAction.buttonText}
        </Link>
      )}
    </div>
  </div>
)

export default Journey
