import React from 'react'
import Link from 'next/link'
import Image from 'next/image'

import { IDatoCMSMasterclassesCategoryTeaser } from '@lib/datocms/types/contentSections'
import Carousel from '@components/Carousel'
import VideoModal from '@components/VideoModal'
import { stripHtml } from '@modules/common/utils'
import ArrowToRightIcon from '@components/icons/ArrowToRightIcon'

interface IMasterclassesCategoryTeaser {
  masterclassesCategoryTeaser: IDatoCMSMasterclassesCategoryTeaser
  id: string
}

const MasterclassesCategoryTeaser: React.FC<IMasterclassesCategoryTeaser> = ({
  masterclassesCategoryTeaser,
  id
}) => {
  const carouselItems: JSX.Element[] = masterclassesCategoryTeaser.masterclassesCategories.map(
    (masterclassesCategory) => {
      const image = masterclassesCategory.imageOrVideo.responsiveImage
      const video = masterclassesCategory.imageOrVideo

      return (
        <div className="flex flex-col mt-4">
          {video.video && <VideoModal video={video} posterFormat="4:3" />}
          <Link legacyBehavior href={masterclassesCategory.link}>
            <a>
              {image && (
                <Image
                  alt={masterclassesCategory.headline}
                  src={image.src}
                  width={image.width}
                  height={image.height}
                  sizes="(min-width: 767px) 33vw, 100vw)"
                />
              )}
              <h4 className="text-xs mt-8 mb-2 uppercase">{masterclassesCategory.overline}</h4>
              <h3 className="text-base font-bold mb-2">{masterclassesCategory.headline}</h3>
              <div
                className="text-base mb-2 line-clamp--5"
                dangerouslySetInnerHTML={{ __html: stripHtml(masterclassesCategory.text) }}
              />
              <div className="btn btn--blank">
                <ArrowToRightIcon />
                <span>zum Video</span>
              </div>
            </a>
          </Link>
        </div>
      )
    }
  )

  return (
    <section id={id} className="bg-white section-content-width section-spacing">
      <h2 className="overline-section mb-2 md:mb-4">{masterclassesCategoryTeaser.headline}</h2>
      <Carousel
        id={id}
        carouselItems={carouselItems}
        slidesToShowMobile={1}
        slidesToShowDesktop={3}
        width="trimmed"
      />
    </section>
  )
}

export default MasterclassesCategoryTeaser
