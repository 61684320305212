import { IDatoCMSImage, IDatoCMSSeoTags } from '@lib/datocms/types'
import { IDatoCMSCallToAction, IDatoCMSSelfHostedVideo } from '@lib/datocms/types/misc'
import { YoImage, ISeoTags, ICallToAction, YoVideo, YoImageOrVideo } from '@modules/common/types'
import { IDatoCMSSleepScore } from '@lib/datocms/types/contentSections'
import { ISleepScore } from '@components/SleepScore/SleepScore'
import { IDatoCMSContentSection, IStep, ModelApiKey } from '@lib/datocms/types/modelApiKeysEnum'

export const mapCallToAction = (data: IDatoCMSCallToAction, targetId?: string): ICallToAction => ({
  externalLink: data?.externalLink || null,
  internalLink: data?.internalLink || null,
  target: targetId || null,
  buttonText: data?.buttonText || null
})

export const mapImage = (data: IDatoCMSImage): YoImage => ({
  alt: data?.alt || data.responsiveImage?.alt || null,
  focalPoint: data?.focalPoint ? `${data.focalPoint.x * 100}% ${data.focalPoint.y * 100}%` : null,
  responsiveImage: data?.responsiveImage || null,
  url: data?.responsiveImage?.src || data.url,
  format: data?.format || null
})

export const mapVideo = (data: IDatoCMSSelfHostedVideo): YoVideo => ({
  title: data.title,
  customData: { customThumbnail: data.customData.customThumbnail, time: data.customData.time },
  height: data.height,
  width: data.width,
  video: {
    thumbnailUrl: data.video?.thumbnailUrl || null,
    mp4Url: data.video?.mp4Url || null
  }
})

export const mapImageOrVideo = (image): YoImageOrVideo => {
  if (image?.responsiveImage) {
    return { ...image, ...mapImage(image) }
  }
  if (image?.video) {
    return { ...image, ...mapVideo(image) }
  }
  return null
}

export const mapImageFromVideo = (data: IDatoCMSSelfHostedVideo): YoImage => ({
  alt: 'video',
  focalPoint: null,
  responsiveImage: {
    height: data.height,
    width: data.width,
    src: data.video.thumbnailUrl
  },
  url: data.video.thumbnailUrl
})

export const mapSeoTags = (data: IDatoCMSSeoTags): ISeoTags => ({
  description: data.description,
  image: {
    url: data.image.url
  },
  title: data.title
})

export const mapSleepScoreSection = (data: IDatoCMSSleepScore): ISleepScore => ({
  // TO MOVE
  id: data.id,
  headline: data.headline,
  text: data.text,
  info: data.info
})

export const mapSteps = (contentSections: IDatoCMSContentSection[]): IStep[] => {
  // get all STEP_DIVIDER as steps
  const steps = contentSections.filter(
    (section) => section._modelApiKey === ModelApiKey.STEP_DIVIDER
  ) as unknown as IStep[]
  return steps
}

export const mapImageOrVideoToYoImage = (imageOrVideo): YoImage => {
  if (imageOrVideo?.responsiveImage) {
    return mapImage(imageOrVideo)
  }
  if (imageOrVideo?.video) {
    return mapImageFromVideo(imageOrVideo)
  }
  return null
}
