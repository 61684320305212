import { IDatoCMSJourney, IDatoCMSJourneyStep } from '@lib/datocms/types/journey'
import { IJourney } from '@modules/journey/types'
import { mapCallToAction } from '@lib/datocms/mapper/misc'

export const mapJourney = (datoCmsJourney: IDatoCMSJourney): IJourney => ({
  id: datoCmsJourney.id,
  overline: datoCmsJourney.overline,
  headline: datoCmsJourney.headline,
  stepOverlineText: datoCmsJourney.stepOverlineText,
  journeySteps: datoCmsJourney.journeySteps.map((journeyStep: IDatoCMSJourneyStep) => ({
    id: journeyStep.id,
    image: journeyStep.image,
    headline: journeyStep.headline,
    text: journeyStep.text
  })),
  conclusionHeadline: datoCmsJourney.conclusionHeadline,
  conclusionText: datoCmsJourney.conclusionText,
  callToAction: mapCallToAction(datoCmsJourney.callToAction[0])
})
