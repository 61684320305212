import React from 'react'
import Image from 'next/image'
import classnames from 'classnames'

import VideoModal from '@components/VideoModal'
import LinkOrAnchorLink from '@components/LinkOrAnchorLink'
import SpinnerIcon from '@components/icons/Spinner'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { ITopXListItem } from '@modules/contentSections/types'

const TopListItem: React.FC<ITopXListItem> = ({
  id,
  itemName,
  image,
  text,
  personalized,
  callToAction,
  isLoading,
  positionIndicator
}) => (
  <div
    className="w-full h-full flex flex-col lg:flex-row bg-gray-200 lg:bg-transparent md:ml-1"
    key={id}>
    <div className="w-full flex">
      {image.responsiveImage && (
        <Image
          alt={image.alt}
          className="w-full object-cover group-hover:scale-105 transition-all"
          src={image.responsiveImage.src}
          width={image.responsiveImage.width}
          height={image.responsiveImage.height}
          sizes="(min-width: 767px) 60vw, 100vw"
        />
      )}
      {image.video && <VideoModal video={image} posterFormat="4:3" />}
    </div>

    <div className="lg:w-[70%] flex flex-col lg:justify-center lg:-ml-20 z-1">
      {isLoading && (
        <div className="absolute self-center">
          <SpinnerIcon />
        </div>
      )}
      <div
        className={classnames({
          'blur-sm opacity-30': isLoading
        })}>
        <div className="bg-gray-200 p-6 lg:p-12 xl:p-16">
          <div className="flex justify-between">
            <h4 className="text-base font-bold mb-5 lg:mb-7">
              {positionIndicator && <span className="mr-2">{positionIndicator}</span>}
              <span>{itemName}</span>
            </h4>
            {personalized && <CheckCircleIcon className="w-10 h-10 min-w-[40px]" />}
          </div>
          <p className="text-base mt-0 text-sections-list lg:line-clamp--10">{text}</p>
          <div className="mt-6 lg:mt-10">
            {callToAction && <LinkOrAnchorLink callToAction={callToAction} blank />}
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default TopListItem
