import React from 'react'
import Image from 'next/image'
import BenefitListItem from '@components/content/contentSection/BenefitListItem'
import LinkOrAnchorLink from '@components/LinkOrAnchorLink'
import { IBenefitList } from '@modules/contentSections/types'

const BenefitList: React.FC<IBenefitList> = ({
  overline,
  headline,
  imagePosition,
  image,
  listOfBenefits,
  callToAction,
  id
}) => {
  const reverse = imagePosition === 'left'
  return (
    <section id={id} className="bg-white relative w-full my-8 xl:my-16">
      <div className="relative section-content-width">
        <div
          className={`flex items-center w-full pt-12 pb-4 md:py-20 ${
            reverse ? 'flex-row-reverse' : ''
          }`}>
          <div className="w-full md:w-1/2 md:pr-12 pr-4">
            <h2 className="overline-section mb-2 md:mb-4">{overline}</h2>
            <h3 className="headline-section max-w-xl mb-8 md:mb-12">{headline}</h3>

            {listOfBenefits.map((benefitListItem) => (
              <BenefitListItem {...benefitListItem} key={benefitListItem.id} />
            ))}

            <div className="mt-6 ml-2 lg:ml-0 lg:mt-10">
              {callToAction && <LinkOrAnchorLink callToAction={callToAction} />}
            </div>
          </div>
          <div
            className={`hidden w-full md:flex md:justify-center md:w-1/2 ${
              reverse ? 'md:pr-3' : 'md:pl-3'
            }`}>
            <Image
              alt={image.alt}
              width={image.responsiveImage.width}
              height={image.responsiveImage.height}
              src={image.responsiveImage.src}
              sizes="(min-width: 767px) 50vw, 100vw"
            />
          </div>
        </div>
      </div>
      {/* Div for gray background */}
      <div
        className={`absolute top-0 bg-gray-200 w-full md:w-9/12 xl:w-8/12 h-full -z-1 ${
          reverse ? 'right-0' : ''
        }`}
      />
    </section>
  )
}

export default BenefitList
