import { BrandsTeaserProps } from '@components/content/contentSection/BrandsTeaser'
import { CategoriesProps } from '@components/content/contentSection/Categories'
import { IImageGallery } from '@components/content/contentSection/ImageGallery'
import { MoodImageProps } from '@components/content/contentSection/MoodImage'
import { IDatoCMSExpertRecommendationRecord } from '@modules/category/types'
import { getTargetForCta } from '@modules/common/utils'
import {
  ExpertRecommendationData,
  ExpertsProps,
  IBenefitList,
  IBrandIntro,
  ICallToActionSection,
  IOneImage,
  ITopXList,
  ThematicRecommendationProps
} from '@modules/contentSections/types'
import { IDatoCMSThematicRecommendationRecord } from '../types'
import {
  IDatoCMSBenefitList,
  IDatoCMSBrandIntro,
  IDatoCMSBrandsTeaserRecord,
  IDatoCMSCallToActionSection,
  IDatoCMSCategoriesTeaserRecord,
  IDatoCMSExpertsTeaserRecord,
  IDatoCMSImageGallery,
  IDatoCMSMoodImageRecord,
  IDatoCMSOneImage,
  IDatoCMSTextContentSection,
  IDatoCMSTopXList
} from '../types/contentSections'
import { IDatoCMSContentSection, InternalLinkBasePath } from '../types/modelApiKeysEnum'
import { mapCallToAction, mapImage, mapImageOrVideo } from './misc'

export const getInternalLink = (model, slug) => {
  const basePath = InternalLinkBasePath[model]
  return basePath ? `${basePath}/${slug}` : basePath
}

export const mapBrandsTeaserSection = (data: IDatoCMSBrandsTeaserRecord): BrandsTeaserProps => ({
  id: data.id,
  headline: data.headline || null,
  brands: data.brands.map((brandCard) => ({
    ...brandCard,
    image: mapImage(brandCard.image),
    brand: {
      ...brandCard.brand,
      logo: mapImage(brandCard.brand.logo)
    }
  }))
})
export const mapCategoriesTeaserSection = (
  data: IDatoCMSCategoriesTeaserRecord
): CategoriesProps => ({
  id: data.id,
  headline: data?.headline || null,
  categories: data.categories.map((oneCategory) => {
    const images = oneCategory.images.map((i) => mapImage(i))
    const { category } = oneCategory
    return {
      id: category.id,
      name: category.name,
      link: getInternalLink(category._modelApiKey, category.slug),
      images,
      text: oneCategory?.text || null
    }
  })
})

export const mapExpertsTeaser = (data: IDatoCMSExpertsTeaserRecord): ExpertsProps => ({
  id: data.id,
  headline: data?.headline || null,
  experts: data.experts.map((oneExpert) => {
    const { expert, image, text } = oneExpert
    return {
      image: mapImageOrVideo(image),
      text,
      slug: expert.slug,
      name: expert.name,
      shortDescription: expert.shortDescription,
      categories: expert.categories.map((category) => ({
        id: category.id,
        name: category.name,
        link: getInternalLink(category._modelApiKey, category.slug)
      }))
    }
  })
})

export const mapThematicRecommendation = (
  data: IDatoCMSThematicRecommendationRecord
): ThematicRecommendationProps => ({
  id: data.id,
  headline: data.headline,
  overline: data.overline,
  image: mapImageOrVideo(data.image), // because it could be video
  text: data.text,
  products: data.products
})

export const mapExpertRecommendation = (
  data: IDatoCMSExpertRecommendationRecord
): ExpertRecommendationData => ({
  id: data.id,
  headline: data.headline,
  image: mapImageOrVideo(data.image), // because it could be video
  text: data.text,
  products: data.products
})

export const mapTopXList = (data: IDatoCMSTopXList, contentSections): ITopXList => ({
  ...data,
  topItemsList: data.topItemsList.map((item) => {
    const targetId = getTargetForCta(item.callToAction[0], contentSections)

    return {
      ...item,
      image: mapImageOrVideo(item.image),
      callToAction:
        item?.callToAction.length > 0 ? mapCallToAction(item.callToAction[0], targetId) : null
    }
  })
})

export const mapTextContentSection = (data: IDatoCMSTextContentSection) => ({
  id: data.id,
  headline: data.headline,
  subheadline: data?.subheadline || null,
  textColumn: data.textColumn,
  textColumnTwo: data.textColumnTwo
})

export const mapCallToActionSection = (
  data: IDatoCMSCallToActionSection,
  contentSections: IDatoCMSContentSection[]
): ICallToActionSection => {
  const targetId = getTargetForCta(data.link[0], contentSections)
  const link = mapCallToAction(data.link[0], targetId)
  return {
    ...data,
    link
  }
}

export const mapBenefitListSection = (
  data: IDatoCMSBenefitList,
  contentSections: IDatoCMSContentSection[]
): IBenefitList => {
  const targetId = getTargetForCta(data.callToAction[0], contentSections)

  return {
    ...data,
    image: mapImage(data.image), // TO DO
    callToAction: mapCallToAction(data.callToAction[0], targetId)
  }
}

export const mapBrandIntro = (
  data: IDatoCMSBrandIntro,
  contentSections: IDatoCMSContentSection[]
): IBrandIntro => {
  const targetId = getTargetForCta(data.callToAction[0], contentSections)

  return {
    ...data,
    image: mapImageOrVideo(data.image), // TO DO
    callToAction: mapCallToAction(data.callToAction[0], targetId)
  }
}

export const mapOneImageBlock = (data: IDatoCMSOneImage): IOneImage => ({
  ...data,
  image: mapImageOrVideo(data.image)
})

export const mapImageGallerySection = (data: IDatoCMSImageGallery): IImageGallery => ({
  ...data,
  images: data.images.map((item) => mapImageOrVideo(item))
})

export const mapMoodImage = (data: IDatoCMSMoodImageRecord): MoodImageProps => ({
  ...data,
  image: mapImageOrVideo(data.image)
})
