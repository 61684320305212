import React, { useContext, useEffect, useRef } from 'react'
import { useInView } from 'framer-motion'
import UiContext, { StepState } from '@context/UiContext'
import trackEvent from '@lib/tracking/trackEvent'
import { IStep } from '@lib/datocms/types/modelApiKeysEnum'

type StepDividerProps = {
  step?: IStep
  bottomId?: string
}

const StepDivider = ({ step, bottomId }: StepDividerProps) => {
  const { stepsState, setStepsState } = useContext(UiContext)

  const ref = useRef<HTMLDivElement>(null)

  const isInView = useInView(ref)

  useEffect(() => {
    if (isInView) {
      const toMarkAsDoneId = ref.current?.getAttribute('data-bottom-id')
      const toMarkAsActiveId = ref.current?.getAttribute('data-top-id')
      setStepsState({
        ...stepsState,
        [toMarkAsDoneId]: StepState.DONE,
        [toMarkAsActiveId]:
          stepsState[toMarkAsActiveId] !== StepState.DONE ? StepState.ACTIVE : StepState.DONE
      })

      if (toMarkAsActiveId) {
        trackEvent({
          event: 'sleep_check_result_section_viewed',
          section: step.title
        })
      }
    }
  }, [isInView])

  return (
    <div
      ref={ref}
      data-bottom-id={bottomId}
      data-top-id={step?.id}
      id={step?.id}
      className="scroll-mt-28"
    />
  )
}

export default StepDivider
