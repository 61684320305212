import React from 'react'
import Image from 'next/image'
import Link from 'next/link'

import { IDatoCMSCategoryPostsTeaser } from '@lib/datocms/types/contentSections'
import ArrowToRightIcon from '@components/icons/ArrowToRightIcon'
import Expander from '@components/Expander'
import { stripHtml } from '@modules/common/utils'

export interface CategoryPostsTeaserProps {
  categoryPostsTeaser: IDatoCMSCategoryPostsTeaser
  id: string
}

const CategoryPostsTeaser = ({ categoryPostsTeaser, id }: CategoryPostsTeaserProps) => (
  <section id={id} className="bg-white section-spacing section-content-width">
    <h2 className="overline-section">Edits in der Kategorie {categoryPostsTeaser.category.name}</h2>
    <div className="flex flex-wrap -mx-1.5 md:-mx-4">
      <Expander initialVisible={4} moreButtonText="Mehr Edits">
        {categoryPostsTeaser.posts &&
          categoryPostsTeaser.posts.map((post) => {
            const image = post.mainImageVideo.responsiveImage
            const { video } = post.mainImageVideo
            return (
              <Link
                legacyBehavior
                href={`/posts/${post.slug}`}
                key={`${categoryPostsTeaser.id}_${post.slug}`}>
                <a className="w-1/2 px-1.5 mt-8 md:w-1/4 md:px-4">
                  {image && (
                    <Image
                      alt={post.headline}
                      src={image.src}
                      width={image.width}
                      height={image.height}
                      sizes="(min-width: 767px) 25vw, 50vw)"
                    />
                  )}
                  {video && (
                    <div className="aspect-h-3 aspect-w-4">
                      <Image
                        alt={post.headline}
                        src={video.thumbnailUrl}
                        fill
                        className="object-cover"
                        sizes="(min-width: 767px) 25vw, 50vw)"
                      />
                    </div>
                  )}
                  <h3 className="font-bold my-2 md:font-bold md:text-lg md:my-4">
                    {post.headline}
                  </h3>
                  <p
                    className="text-sm md:text-base line-clamp--5"
                    dangerouslySetInnerHTML={{ __html: stripHtml(post.summary) }}
                  />
                  <div className="hidden btn btn--blank mt-2 md:flex">
                    <ArrowToRightIcon />
                    <span>MEHR</span>
                  </div>
                </a>
              </Link>
            )
          })}
      </Expander>
    </div>
  </section>
)

export default CategoryPostsTeaser
