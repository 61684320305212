import React from 'react'
import Image from 'next/image'

import VideoPlayer from '@components/VideoPlayer'
import { YoImageOrVideo } from '@modules/common/types'

export type MoodImageProps = {
  quote: string
  image: YoImageOrVideo
  id: string
}

const MoodImage = ({ quote, image, id }: MoodImageProps) => (
  <section id={id} className="w-full mb-8 md:mb-24">
    {image.responsiveImage && (
      <div className="relative flex w-full h-90vh">
        <Image
          alt={image.alt}
          src={image.responsiveImage.src}
          blurDataURL={image.responsiveImage.base64}
          placeholder="blur"
          fill
          className="object-cover"
          style={{ objectPosition: image.focalPoint }}
          sizes="100vw"
        />
      </div>
    )}
    {image.video && <VideoPlayer video={image} />}
    <div className="flex flex-wrap px-4 py-4 lg:px-8 items-start h-auto mx-auto md:mt-2">
      <div className="w-full">
        <p role="doc-subtitle" className="text-base xl:text-lg md:mt-0.5 font-bold">
          {quote}
        </p>
      </div>
    </div>
  </section>
)

export default MoodImage
