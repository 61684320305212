import React from 'react'
import Link from 'next/link'
import Image from 'next/image'

import ArrowToRightIcon from '@components/icons/ArrowToRightIcon'
import { YoImage } from '@modules/common/types'

export type BrandCardProps = {
  id: string
  text: string
  image: YoImage
  brand: {
    name: string
    logo: YoImage
    slug: string
  }
}

const BrandCard = ({ id, image, text, brand }: BrandCardProps) => (
  <Link legacyBehavior href={`/brands/${brand.slug}`} id={id}>
    <a className="flex flex-col h-full w-full group">
      <div className="overflow-hidden">
        <Image
          alt={image.alt}
          src={image.responsiveImage.src}
          className="md:group-hover:scale-105 transition-all"
          height={image.responsiveImage.height}
          width={image.responsiveImage.width}
          sizes="(min-width: 767px) 33vw, 100vw"
        />
      </div>
      <div className="flex flex-col bg-white p-4 items-start h-auto w-full flex-grow md:p-8">
        <div className="flex items-center h-24 w-full relative mb-4">
          <Image
            alt={brand.logo.alt}
            className="h-24 mb-4 object-contain"
            src={brand.logo.responsiveImage?.src || brand.logo.url}
            fill
            style={{ objectPosition: 'left center' }}
            sizes="(min-width: 767px) 33vw, 100vw"
          />
        </div>

        <p role="doc-subtitle" className="text-base my-4">
          {text}
        </p>
        <div className="flex items-center link-style my-4" title="Mehr Infos">
          <ArrowToRightIcon />
          <span className="pl-2 group-hover:underline uppercase">Mehr Infos</span>
        </div>
      </div>
    </a>
  </Link>
)

export default BrandCard
