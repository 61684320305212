import React from 'react'

import { IDatoCMSCommunityStoryOverview } from '@lib/datocms/types/contentSections'
import Carousel from '@components/Carousel'
import VideoModal from '@components/VideoModal'

interface ICommunityStoryOverview {
  communityStoryOverview: IDatoCMSCommunityStoryOverview
  id: string
}

const CommunityStoryOverview: React.FC<ICommunityStoryOverview> = ({
  communityStoryOverview,
  id
}) => {
  const carouselItems: JSX.Element[] = communityStoryOverview.communityStoryList.map((listItem) => (
    <div key={listItem.id} className="flex flex-col flex-wrap justify-end sm:flex-nowrap w-full">
      <VideoModal video={listItem.video} />
      <div className="w-full mt-4">
        <h3 className="font-bold text-base md:text-lg w-full group-hover:underline mb-6">
          {listItem.name}
        </h3>
        <div className="non-bold-blockquote">
          <blockquote className="text-base">{listItem.quote}</blockquote>
        </div>
      </div>
    </div>
  ))

  return (
    <section id={id} className="bg-white section-spacing section-content-width h-1/4">
      <h2 className="overline-section mb-8 md:mb-10">{communityStoryOverview.headline}</h2>
      <Carousel
        id={id}
        carouselItems={carouselItems}
        slidesToShowMobile={1}
        slidesToShowDesktop={4}
        width="trimmed"
        gap={4}
      />
    </section>
  )
}

export default CommunityStoryOverview
