import React from 'react'
import Image from 'next/image'

import { IDatoCMSTopicIntro } from '@lib/datocms/types/contentSections'
import { isVideoLandscape, isVideoPortrait } from '@modules/common/utils'
import VideoModal from '@components/VideoModal'
import VideoPlayer from '@components/VideoPlayer'
import LinkOrAnchorLink from '@components/LinkOrAnchorLink'

interface ITopicIntro {
  topicIntro: IDatoCMSTopicIntro
  id: string
}

const TopicIntro: React.FC<ITopicIntro> = ({ topicIntro, id }) => {
  const reverse = topicIntro.imagePosition === 'right'
  return (
    <section id={id} className="bg-white section-content-width section-spacing">
      <div
        className={`flex flex-col md:flex-row ${reverse ? 'md:flex-row-reverse' : 'md:flex-row'}`}>
        <div
          className={`order-last md:order-first flex w-full md:w-1/2 items-center ${
            reverse ? 'md:pl-3' : 'md:pr-3'
          }`}>
          <div className="max-w-xl">
            <div className="hidden md:block w-full max-w-200px mb-8">
              <Image
                alt={topicIntro.brandLogo.alt}
                width={topicIntro.brandLogo.responsiveImage.width}
                height={topicIntro.brandLogo.responsiveImage.height}
                src={topicIntro.brandLogo.responsiveImage.src}
                sizes="(max-width: 767px) 100vh, 50vw"
              />
            </div>

            <h2 className="headline-intro mb-6">{topicIntro.headline}</h2>

            <div
              className="text-content-section intro-sections-list pb-8 md:pb-10"
              dangerouslySetInnerHTML={{ __html: topicIntro.text }}
            />

            <LinkOrAnchorLink callToAction={topicIntro.callToAction[0]} />
          </div>
        </div>
        <div
          className={`order-first md:order-last relative flex flex-wrap w-full md:w-1/2 items-center mb-8 md:mb-0 ${
            reverse ? 'md:pr-3' : 'md:pl-3'
          }`}>
          <div className="md:hidden w-full max-w-150px mb-8 mx-auto">
            <Image
              alt={topicIntro.brandLogo.alt}
              width={topicIntro.brandLogo.responsiveImage.width}
              height={topicIntro.brandLogo.responsiveImage.height}
              src={topicIntro.brandLogo.responsiveImage.src}
              sizes="(max-width: 767px) 100vh, 50vw"
            />
          </div>
          {topicIntro.image.responsiveImage && (
            <Image
              alt={topicIntro.image.alt}
              width={topicIntro.image.responsiveImage.width}
              height={topicIntro.image.responsiveImage.height}
              src={topicIntro.image.responsiveImage.src}
              priority
            />
          )}
          {isVideoPortrait(topicIntro.image) && <VideoModal video={topicIntro.image} />}
          {isVideoLandscape(topicIntro.image) && <VideoPlayer video={topicIntro.image} />}
        </div>
      </div>
    </section>
  )
}

export default TopicIntro
