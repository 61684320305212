import React, { useState } from 'react'
import ArrowToRightIcon from '@components/icons/ArrowToRightIcon'

interface IExpander {
  initialVisible: number
  moreButtonText: string
  children: JSX.Element[]
}

const Expander: React.FC<IExpander> = ({ initialVisible, moreButtonText, children }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <>
      {children && children.filter((_, index) => index < initialVisible || isExpanded)}
      {children && children.length > initialVisible && !isExpanded && (
        <button
          type="button"
          className="btn btn--black mx-auto mt-8"
          onClick={() => {
            setIsExpanded(true)
          }}>
          <ArrowToRightIcon />
          <span>{moreButtonText}</span>
        </button>
      )}
    </>
  )
}

export default Expander
