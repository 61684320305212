import { getTargetForCta } from '@modules/common/utils'
import { ITopic } from '@modules/topics/types'
import { IDatoCMSTopicPage } from '../types/topic'
import { mapCallToAction, mapImage, mapSteps } from './misc'

export const mapTopicPage = (data: IDatoCMSTopicPage): ITopic => {
  if (!data) {
    return undefined
  }
  const stageSection = data.stageSection[0]
  const hasCallToAction = stageSection.callToAction.length > 0
  const targetId = getTargetForCta(stageSection.callToAction[0], data.contentSections)

  return {
    ...data,
    stageSection: {
      ...stageSection,
      image: mapImage(stageSection.stageImage),
      callToAction: hasCallToAction ? mapCallToAction(stageSection.callToAction[0], targetId) : null
    },
    steps: mapSteps(data.contentSections)
  }
}
