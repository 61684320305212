import React from 'react'

import Image from 'next/image'

import VideoModal from '@components/VideoModal'
import { YoImageOrVideo } from '@modules/common/types'

const ExpertSlide = ({
  image,
  text,
  name
}: {
  image: YoImageOrVideo
  text?: string
  name: string
}) => (
  <div className="flex flex-wrap group w-full">
    <div className="w-full overflow-hidden">
      {image.responsiveImage && (
        <Image
          alt={image.alt}
          src={image.responsiveImage}
          width={image.responsiveImage.width}
          height={image.responsiveImage.height}
          className="md:group-hover:scale-105 transform transition-all"
          sizes="(min-width: 767px) 33vw, 100vw"
        />
      )}
      {image.video && <VideoModal video={image} />}
    </div>

    <div className="flex flex-wrap mt-4 md:mt-6">
      <h3 className="font-bold text-base xl:text-lg w-full text-left">{name}</h3>

      <div
        role="doc-subtitle"
        className="text-base pr-4 text-left"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  </div>
)

export default ExpertSlide
