import React from 'react'

const CheckCircleIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g id="Ebene_2" data-name="Ebene 2">
      <g id="Ebene_1-2" data-name="Ebene 1">
        <g id="Gruppe_234" data-name="Gruppe 234">
          <path
            id="Pfad_132"
            data-name="Pfad 132"
            d="M8.736,16.194l6.03,4.853,7.639-8.952"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeWidth="2"
          />
          <g id="Ellipse_32" data-name="Ellipse 32">
            <circle cx="16" cy="16" r="15" fill="none" stroke="#000" strokeWidth="2" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default CheckCircleIcon
