import React from 'react'
import Image from 'next/image'

import ArrowToRightIcon from '@components/icons/ArrowToRightIcon'
import { IDatoCMSNewsLetterSignUp } from '@lib/datocms/types'
import Link from 'next/link'

const NewsletterSignUp = ({ link, id }: IDatoCMSNewsLetterSignUp) => {
  const newsletterImage = link.image

  return (
    <section id={id} className="w-full md:mb-4">
      <div className="relative md:items-end py-32 2xl:py-48 ">
        <Image
          src={newsletterImage.responsiveImage.src}
          blurDataURL={newsletterImage.responsiveImage.base64}
          placeholder="blur"
          fill
          className="object-cover"
          alt={newsletterImage.alt || 'Newsletter Image'}
          sizes="100vw"
        />
        <div className="relative w-full h-full ">
          <div className="flex w-full h-full justify-center md:justify-end items-center section-content-width">
            <div className="flex justify-center items-center w-full md:w-3/5 lg:w-1/2 p-12 bg-white">
              <div className="h-full flex flex-col items-start justify-start flex-wrap bg-white ">
                <h2 className="font-extrabold text-base md:text-lg mb-6 uppercase">
                  {link.headline}
                </h2>
                <p className="font-bold text-base lg:text-lg mb-6">{link.text}</p>
                <Link href={link.callToAction[0]?.externalLink || '#'}>
                  <button className="btn btn--black" type="button">
                    <ArrowToRightIcon />
                    <span>{link.callToAction[0].buttonText}</span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default NewsletterSignUp
