import React from 'react'

import { IContentSection } from '@modules/contentSections/types'
import Carousel from '@components/Carousel'
import BrandCard, { BrandCardProps } from './Brands/BrandCard'

export type BrandsTeaserProps = IContentSection & {
  brands: BrandCardProps[]
}
const BrandsTeaser = ({ id, headline, brands }: BrandsTeaserProps) => {
  const brandCards = brands.map((brand) => <BrandCard {...brand} key={brand.id} />)

  return (
    <section id={id} className="section-spacing bg-gray-200">
      <div className="section-content-width">
        <h2 className="overline-section">{headline}</h2>
        <div className="my-8 md:my-10">
          <Carousel
            id={id}
            carouselItems={brandCards}
            slidesToShowMobile={1}
            slidesToShowDesktop={3}
            width="trimmed"
          />
        </div>
      </div>
    </section>
  )
}

export default BrandsTeaser
