import React from 'react'

import Carousel from '@components/Carousel'
import { ITopXList } from '@modules/contentSections/types'
import TopListItem from './TopListItem'

const TopXList: React.FC<ITopXList> = ({ topItemsList, headline, id }) => {
  const carouselIconItems = topItemsList.map((listItem) => <TopListItem {...listItem} />)

  return (
    <section id={id} className="bg-white section-content-width section-spacing">
      <div className="mb-8">
        <h3 className="overline-section mb-4 md:ml-1">{headline}</h3>
        <Carousel
          id={id}
          carouselItems={carouselIconItems}
          slidesToShowMobile={1}
          slidesToShowDesktop={1}
          width="fullOnDesktop"
        />
      </div>
    </section>
  )
}
export default TopXList
