import {
  IDatoCMSPersonalizedPostsTeaser,
  IDatoCMSPersonalizedSleepTipList,
  IDatoCMSRule,
  IPersonalizedHeroProduct,
  PersonalizedPostsTeaserProps,
  IPersonalizedSleepTipList,
  IDatoCMSPersonalizableTextProductAndPost,
  IDatoCMSPersonalizableTextProductAndPostContent
} from '@lib/datocms/types/personalizedContent'
import { ISleepCheckEntry } from '@lib/sleepcheck/types'
import { IDatoCMSHeroProduct, IProductSnippetsBySlug } from '@modules/products/types'
import { YoMinimalImage } from '@modules/common/types'
import { PersonalizedTextProductAndPostProps } from '@modules/contentSections/types'
import {
  IDatoCMSPersonalizableHeroProductList,
  PersonalizedHeroProductListProps
} from '@lib/datocms/types/personalizedContent/heroProductList'
import { mapCallToAction, mapImageOrVideo } from './misc'

const toBoolean = (value: string | boolean): boolean => value === true || value === 'true'
const doesContain = (attributeValues: Array<string>, value: string): boolean =>
  attributeValues.find((attributeValue) => attributeValue.toLowerCase() === value.toLowerCase()) !==
  undefined

const ruleApplies = (rule: IDatoCMSRule, sleepCheckResults: ISleepCheckEntry): boolean => {
  const { attribute, operator, value } = rule

  const attributeValue: boolean | Array<string> | number =
    operator === 'greater than' || operator === 'less than'
      ? sleepCheckResults.scores[attribute]
      : sleepCheckResults.data[attribute]

  if (operator === 'equals' && attributeValue === toBoolean(value)) {
    return true
  }
  if (operator === 'does not equal' && attributeValue !== toBoolean(value)) {
    return true
  }
  if (operator === 'does contain' && doesContain(attributeValue as Array<string>, value)) {
    return true
  }
  if (operator === 'does not contain' && !doesContain(attributeValue as Array<string>, value)) {
    return true
  }
  if (operator === 'greater than' && +attributeValue > parseInt(value, 10)) {
    return true
  }
  if (operator === 'less than' && +attributeValue < parseInt(value, 10)) {
    return true
  }
  return false
}

export const mapPersonalizedSleepTipList = (
  data: IDatoCMSPersonalizedSleepTipList,
  sleepCheckResults: ISleepCheckEntry
): IPersonalizedSleepTipList => {
  if (!sleepCheckResults) {
    return {
      id: data.id,
      headline: data.headline,
      items: data.link.sleepTipContainer.map((item) => ({
        id: item.id,
        itemName: item.itemName,
        text: item.text,
        personalized: false,
        callToAction: item.callToAction.length > 0 ? mapCallToAction(item?.callToAction[0]) : null,
        image: mapImageOrVideo(item.image)
      }))
    }
  }

  return {
    id: data.id,
    headline: data.headline,
    items: data.link.sleepTipContainer.map((item) => {
      if (!item.personalizable) {
        return {
          id: item.id,
          itemName: item.itemName,
          text: item.text,
          personalized: false,
          callToAction:
            item.callToAction.length > 0 ? mapCallToAction(item?.callToAction[0]) : null,
          image: mapImageOrVideo(item.image)
        }
      }

      const [text, itemName, personalized] = item.alternativeContent.reduce(
        (prev, alternative) => {
          const { conditions, text: altText, itemName: altHeader } = alternative
          const rulesPass = conditions.every(({ rules }) =>
            rules.some((ruleItem) => ruleApplies(ruleItem, sleepCheckResults))
          )
          if (rulesPass) {
            return [altText, altHeader, true]
          }
          return prev
        },
        [item.text, item.itemName, false]
      )

      return {
        id: item.id,
        callToAction: item.callToAction.length > 0 ? mapCallToAction(item?.callToAction[0]) : null,
        personalized,
        image: mapImageOrVideo(item.image),
        itemName,
        text
      }
    })
  }
}

export const mapPersonalizedPostsTeaser = (
  data: IDatoCMSPersonalizedPostsTeaser,
  sleepCheckResults: ISleepCheckEntry
): PersonalizedPostsTeaserProps => {
  if (!sleepCheckResults) return null
  return {
    id: data.id,
    headline: data.headline,
    posts: data.posts.map((item) => {
      const rulesPass = item.conditions.every(({ rules }) =>
        rules.some((oneRule) => ruleApplies(oneRule, sleepCheckResults))
      )
      if (rulesPass) {
        const { post } = item

        return {
          id: post.id,
          slug: post.slug,
          headline: post.headline,
          category: post.category,
          author: post.author,
          summary: post.summary,
          mainImageVideo: post.mainImageVideo,
          _firstPublishedAt: post._firstPublishedAt
        }
      }
      return null
    })
  }
}

export const mapPersonalizedHeroProduct = (
  data: IDatoCMSHeroProduct,
  sleepCheckResults: ISleepCheckEntry
): IPersonalizedHeroProduct => {
  if (!sleepCheckResults || !data.personalizable) {
    return {
      id: data.id,
      headline: data.headline,
      overline: data.overline,
      text: data.text,
      heroImage: mapImageOrVideo(data.heroImage),
      product: data.product,
      personalized: false
    }
  }
  const [headline, overline, text, heroImage, product, personalized] =
    data.alternativeContent.reduceRight(
      (prev, alternative) => {
        const {
          conditions,
          headline: altHeadline,
          overline: altOverline,
          text: altText,
          heroImage: altHeroImage,
          product: altProduct
        } = alternative
        const rulesPass = conditions.some(({ rules }) =>
          rules.every((ruleItem) => ruleApplies(ruleItem, sleepCheckResults))
        )
        if (rulesPass) {
          return [altHeadline, altOverline, altText, altHeroImage, altProduct, true]
        }
        return prev
      },
      [data.headline, data.overline, data.text, data.heroImage, data.product, false]
    )

  const mappedImage = mapImageOrVideo(heroImage)
  return {
    id: data.id,
    headline,
    overline,
    text,
    heroImage: mappedImage,
    product,
    personalized
  }
}

const getPersonalizedTextProductAndPostContent = (
  personalizedTextProductAndPostContent: IDatoCMSPersonalizableTextProductAndPostContent,
  productSnippetsBySlug: IProductSnippetsBySlug
) => ({
  id: personalizedTextProductAndPostContent.id,
  subheadline: personalizedTextProductAndPostContent.subheadline,
  text: personalizedTextProductAndPostContent.text,
  productSnippets: personalizedTextProductAndPostContent.productLinks.map(
    (productLink) => productSnippetsBySlug[productLink.shopifyProductId]
  ),
  postTeasers: personalizedTextProductAndPostContent.postLinks.map((postLink) => ({
    id: postLink.id,
    slug: postLink.slug,
    headline: postLink.headline,
    category: postLink.category,
    author: postLink.author,
    summary: postLink.summary,
    mainImage: {
      src: postLink.mainImageVideo.responsiveImage?.src
        ? postLink.mainImageVideo.responsiveImage.src
        : postLink.mainImageVideo.video.thumbnailUrl,
      alt: postLink.mainImageVideo.alt || postLink.mainImageVideo.title
    } as YoMinimalImage
  }))
})

const getPersonalizedTextAndProductProps = (
  data: IDatoCMSPersonalizableTextProductAndPost,
  productSnippetsBySlug: IProductSnippetsBySlug
): PersonalizedTextProductAndPostProps => ({
  id: data.id,
  headline: data.headline || null,
  subheadline: data.subheadline || null,
  personalized: false,
  personalizedTextProductAndPostContentList: data.personalizableTextProductAndPostContentList.map(
    (personalizedTextProductAndPostContent) =>
      getPersonalizedTextProductAndPostContent(
        personalizedTextProductAndPostContent,
        productSnippetsBySlug
      )
  )
})

export const mapPersonalizedTextProductAndPost = (
  data: IDatoCMSPersonalizableTextProductAndPost,
  productSnippetsBySlug: IProductSnippetsBySlug,
  sleepCheckResults: ISleepCheckEntry
): PersonalizedTextProductAndPostProps => {
  if (!sleepCheckResults) {
    return getPersonalizedTextAndProductProps(data, productSnippetsBySlug)
  }
  return {
    id: data.id || null,
    headline: data.headline || null,
    subheadline: data.subheadline || null,
    personalized: true,
    personalizedTextProductAndPostContentList: data.personalizableTextProductAndPostContentList.map(
      (personalizedTextProductAndPostContent) => {
        if (personalizedTextProductAndPostContent.alternativeParagraphProductsOrPostElement) {
          const [id, subheadline, text, productLinks, postLinks] =
            personalizedTextProductAndPostContent.alternativeParagraphProductsOrPostElement.reduceRight(
              (prev, alternative) => {
                const {
                  conditions,
                  id: altId,
                  subheadline: altSubheadline,
                  text: altText,
                  productLinks: altProductLinks,
                  postLinks: altPostTeasers
                } = alternative
                const rulesPass = conditions.some(({ rules }) =>
                  rules.every((ruleItem) => ruleApplies(ruleItem, sleepCheckResults))
                )
                if (rulesPass) {
                  return [altId, altSubheadline, altText, altProductLinks, altPostTeasers]
                }
                return prev
              },
              [
                personalizedTextProductAndPostContent.id,
                personalizedTextProductAndPostContent.subheadline,
                personalizedTextProductAndPostContent.text,
                personalizedTextProductAndPostContent.productLinks,
                personalizedTextProductAndPostContent.postLinks
              ]
            )
          return getPersonalizedTextProductAndPostContent(
            {
              id,
              subheadline,
              text,
              productLinks,
              postLinks
            } as IDatoCMSPersonalizableTextProductAndPostContent,
            productSnippetsBySlug
          )
        }

        return getPersonalizedTextProductAndPostContent(
          personalizedTextProductAndPostContent,
          productSnippetsBySlug
        )
      }
    )
  }
}

export const mapPersonalizedHeroProductList = (
  data: IDatoCMSPersonalizableHeroProductList,
  productSnippetsBySlug: IProductSnippetsBySlug,
  sleepCheckResults: ISleepCheckEntry
): PersonalizedHeroProductListProps => {
  if (!sleepCheckResults) {
    return {
      id: data.id,
      headline: data.headline || null,
      subheadline: data.subheadline || null,
      personalized: false,
      personalizedHeroProductList: data.heroProductListContentField
        .filter((personalizedHeroProduct) => !!personalizedHeroProduct.text)
        .map((personalizedHeroProduct) => ({
          id: personalizedHeroProduct.id || null,
          headline: personalizedHeroProduct.headline || null,
          overline: personalizedHeroProduct.overline || null,
          text: personalizedHeroProduct.text || null,
          heroImage: mapImageOrVideo(personalizedHeroProduct.heroImage) || null,
          product: personalizedHeroProduct.product || null,
          personalized: false
        })),
      productSnippetsBySlug
    }
  }
  return {
    id: data.id || null,
    headline: data.headline || null,
    subheadline: data.subheadline || null,
    personalized: true,
    productSnippetsBySlug,
    personalizedHeroProductList: data.heroProductListContentField
      .map((personalizedHeroProduct) => {
        if (personalizedHeroProduct.alternativeContent) {
          const [headline, overline, text, heroImage, product, personalized] =
            personalizedHeroProduct.alternativeContent.reduceRight(
              (prev, alternative) => {
                const {
                  conditions,
                  headline: altHeadline,
                  overline: altOverline,
                  text: altText,
                  heroImage: altHeroImage,
                  product: altProduct
                } = alternative
                const rulesPass = conditions.some(({ rules }) =>
                  rules.every((ruleItem) => ruleApplies(ruleItem, sleepCheckResults))
                )
                if (rulesPass) {
                  return [altHeadline, altOverline, altText, altHeroImage, altProduct, true]
                }
                return prev
              },
              [
                personalizedHeroProduct.headline,
                personalizedHeroProduct.overline,
                personalizedHeroProduct.text,
                personalizedHeroProduct.heroImage,
                personalizedHeroProduct.product,
                false
              ]
            )
          return {
            id: personalizedHeroProduct.id,
            headline,
            overline,
            text,
            heroImage: mapImageOrVideo(heroImage),
            product,
            personalized
          }
        }
        return {
          id: personalizedHeroProduct.id,
          headline: personalizedHeroProduct.headline,
          overline: personalizedHeroProduct.overline,
          text: personalizedHeroProduct.text,
          heroImage: mapImageOrVideo(personalizedHeroProduct.heroImage),
          product: personalizedHeroProduct.product,
          personalized: false
        }
      })
      .filter((personalizedHeroProduct) => !!personalizedHeroProduct.text)
  }
}
