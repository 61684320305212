import React, { useContext } from 'react'
import SleepCheckContext from '@context/SleepCheckContext'

type GptAssessmentProps = {
  headline: string
}

const GptAssessment = ({ headline }: GptAssessmentProps) => {
  const { result } = useContext(SleepCheckContext)

  if (!result || !result.chatResponse) {
    return null
  }
  const { chatResponse } = result
  const formattedChatResponse = JSON.parse(chatResponse).replace(/\n/g, '<br>').replace(/"/g, '"')

  return (
    <section className="bg-white section-spacing section-content-width max-w-content-sm md:m-auto">
      {headline && <h2 className="headline-section mb-2">{headline}</h2>}
      {formattedChatResponse && (
        <>
          <h2 className="headline-section mb-6">Unsere Einschätzung</h2>
          <div className="mb-4" dangerouslySetInnerHTML={{ __html: formattedChatResponse }} />
          <p>
            <b>
              Berücksichtige bitte, dass diese Einschätzung aufgrund der bereitgestellten
              Informationen gemacht wurde und ein persönliches Gespräch mit einem Schlafexperten
              ratsam ist, um eine individuelle Lösung zu finden.
            </b>
          </p>
        </>
      )}
    </section>
  )
}

export default GptAssessment
